import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public jwtHelper: JwtHelperService) {
    console.error("AuthService");
  }

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('@@user')
    return !this.jwtHelper.isTokenExpired(token);
  }
}
