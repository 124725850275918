import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class WorkspaceService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrlWorkspace;
  }

  public async checkWorkspaceAvailability(email) {
    const reply = await this.httpClient
      .get(`${this.baseUrl}/workspace/owner/${email}`)
      .toPromise();
    return reply && !reply["isSuccess"];
  }

  public async getWorkspacesByOwnerName(email) {
    const reply = await this.httpClient
      .get(`${this.baseUrl}/workspace/owner/${email}`)
      .toPromise();
    return reply && reply["isSuccess"] ? reply["result"] : [];
  }

  public async checkWorkspaceNameAvailability(workspaceName,token:string=null) {
    const reply = await this.httpClient
      .get(`${this.baseUrl}/workspace/${workspaceName}${token? `?g-recaptcha-response=${token}`:''}`)
      .toPromise();
    return reply && reply["isSuccess"];
  }

  public async checkAvailability(domain,token:string=null) {
    const reply = await this.httpClient
      .get(`${this.baseUrl}/workspace/officialdomain/${domain}${token? `?g-recaptcha-response=${token}`:''}`)
      .toPromise();
    return reply ;
  }

  public async isOfficialDomain(domain,token:string=null) {
    const reply = await this.httpClient
      .get(`${this.baseUrl}/workspace/officialdomain/${domain}/official${token? `?g-recaptcha-response=${token}`:''}`)
      .toPromise();
    return (reply && reply["isSuccess"])? reply["result"] : null;
  }

  public async update_official_domain(workspaceId, domain,autoAcceptRequest,token:string=null) {
    const postData = {
      officialDomain: domain,
      autoAcceptRequest: autoAcceptRequest,
    };
    const jsonStr = JSON.stringify(postData);
    const reply = await this.httpClient
      .put(`${this.baseUrl}/workspace/${workspaceId}/officialdomain${token? `?g-recaptcha-response=${token}`:''}`,jsonStr)
      .toPromise();
    return reply && reply["isSuccess"];
  }

  public async save_join_request(workspaceId, userId,username,token:string=null) {
    const postData = {
      username: username
    };
    const jsonStr = JSON.stringify(postData);
    const reply = await this.httpClient
      .post(`${this.baseUrl}/workspace/${workspaceId}/domain/user/${userId}${token? `?g-recaptcha-response=${token}`:''}`,jsonStr)
      .toPromise();
    return reply && reply["isSuccess"];
  }

  public async registerWorkspace(workspace, owner,token:string=null) {
    const postData = {
      workSpaceName: workspace,
      owner: owner,
    };

    const jsonStr = JSON.stringify(postData);
    return this.httpClient
      .post(`${this.baseUrl}/workspace/register${token? `?g-recaptcha-response=${token}`:''}`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
  }

  public async removeFromWorkspace(owner, workspaceid,workSpaceName, memberId,userId,userName) {
    const reply = await this.httpClient
      .delete(`${this.baseUrl}/workspace/${workSpaceName}/${workspaceid}/${owner}/${memberId}/${userId}/${userName}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
    return reply && reply["isSuccess"];
  }

  public async cancelInvitation(owner, workspaceid, workspacename, memberId) {
    //workspace/:workSpaceName/:workspaceId/:owner/:memberId/invite
    const reply = await this.httpClient
      .delete(
        `${this.baseUrl}/workspace/${workspacename}/${workspaceid}/${owner}/${memberId}/invite`,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .toPromise();
    return reply && reply["isSuccess"];
  }

  public async sendInvitations(
    contacts,
    owner,
    workspaceid,
    workspacename,
    memberId,userType,token:string=null
  ) {
    const postdata = {
      contacts: contacts,
      user_type :userType
    };

    let reply;

    if (memberId) {
      const jsonStr = JSON.stringify(postdata);
      reply = await this.httpClient
        .put(
          `${this.baseUrl}/workspace/${workspacename}/${workspaceid}/${owner}/${memberId}/invite`,jsonStr,
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
            }),
          }
        )
        .toPromise();
    } else {
      const jsonStr = JSON.stringify(postdata);
      reply = await this.httpClient
        .post(
          `${this.baseUrl}/workspace/${workspacename}/${workspaceid}/${owner}/invite${token? `?g-recaptcha-response=${token}`:''}`,
          jsonStr,
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
            }),
          }
        )
        .toPromise();
    }

    return reply && reply["isSuccess"];
  }

  public async getMembers(
    workspaceName: string,
    userType: string,
    owner: string,
    page: number,
    pageSize:number = 1000
  ) {
    const reply = await this.httpClient
      .get(
        `${this.baseUrl}/workspace/${workspaceName}/owner/${owner}/members/${userType}/${page}/${pageSize}`
      )
      .toPromise();
    return reply && reply["isSuccess"] ? reply["result"] : [];
  }

  public async updateUserWorkspace(username, workspaceName,token:string=null) {
    try {
      const reply = await this.httpClient
      .put(
        `${this.baseUrl}/workspace/${workspaceName}/user/${username}${token? `?g-recaptcha-response=${token}`:''}`,
        {},
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .toPromise();

      return reply && reply["isSuccess"];
    } catch (error) {
      console.error("workspace service","updateUserWorkspace", error);
      return false;
    }
    
  }

  public async getPasswordPolicy(userName,token:string=null) {
    const reply = await this.httpClient
      .get(`${this.baseUrl}/workspace/member/${userName}/password/policy${token? `?g-recaptcha-response=${token}`:''}`)
      .toPromise();
    return reply && reply["isSuccess"] ? reply["result"] : null;
  }

  public async getWorkspaceByUserName(userName,token:string=null) {
    const reply = await this.httpClient
      .get(`${this.baseUrl}/workspace/member/${userName}${token? `?g-recaptcha-response=${token}`:''}`)
      .toPromise();
    return reply && reply["isSuccess"] ? reply["result"] : null;
  }

  public async getMyMemberDetails(userName,token:string=null) {
    const reply = await this.httpClient
      .get(`${this.baseUrl}/workspacemember/user/${userName}${token? `?g-recaptcha-response=${token}`:''}`)
      .toPromise();
    return reply && reply["isSuccess"] ? reply["result"] : null;
  }


  public async savePasswordPolicy(workspaceid, passwordPolicy,policyNames,forcePasswordChange,oldPasswords,maxLength,minLength,token:string=null) {
    const postdata = {
      passwordPolicy: passwordPolicy,
      policyNames:policyNames,
      forcePasswordChangeIn:forcePasswordChange,
      notAllowedLastPassword:oldPasswords,
      maxLength:maxLength,
      minLength:minLength
    };

    const jsonStr = JSON.stringify(postdata);
    return this.httpClient
      .post(`${this.baseUrl}/workspace/${workspaceid}/password/policy${token? `?g-recaptcha-response=${token}`:''}`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
  }

  public async acceptJoinRequest(workspaceid,requestId,userType) {

    const postdata = {
      "userType":userType
    };
    const jsonStr = JSON.stringify(postdata);
    const reply = await this.httpClient
      .post(`${this.baseUrl}/workspace/${workspaceid}/domain/join/request/${requestId}`,jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
    return reply && reply["isSuccess"];
  }

  public async deleteJoinRequest(workspaceid,requestId) {
    const reply = await this.httpClient
      .delete(`${this.baseUrl}/workspace/${workspaceid}/domain/join/request/${requestId}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
    return reply && reply["isSuccess"];
  }

  public async IsUserExistsInTheOrganization(workspaceid,userid,token:string=null) {
    const data = await this.httpClient
      .get(`${this.baseUrl}/workspace/${workspaceid}/user/${userid}${token? `?g-recaptcha-response=${token}`:''}`)
      .toPromise();
    return data && data["isSuccess"] && data["result"] !== null;
  }


  public async getUserRolesWithFeatures(workspaceId:string, token:string=null) {
    const data = await this.httpClient
      .get(`${this.baseUrl}/workspace/${workspaceId}/configurations/member${token? `?g-recaptcha-response=${token}`:''}`)
      .toPromise();
    return (data && data["isSuccess"] === true) ? data["result"] : null;
  }

  public async saveUserRole(role,description,workspaceId) {
    const postdata = {
      role,
      description,
      workspaceId
    };
    const jsonStr = JSON.stringify(postdata);
    const reply = await this.httpClient
      .post(`${this.baseUrl}/workspace/configuration/role`,jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
    return reply && reply["isSuccess"];
  }

  public async addUserToRole(workspaceId,role,members,removedMembers) {
    const postdata = { members ,removedMembers};
    const jsonStr = JSON.stringify(postdata);
    const reply = await this.httpClient
      .put(`${this.baseUrl}/workspace/${workspaceId}/configuration/role/${role}/members`,jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
    return reply && reply["isSuccess"];
  }


  public async getSystemFeatures(token:string=null) {
    const data = await this.httpClient
      .get(`${this.baseUrl}/workspace/configuration/features`)
      .toPromise();
    return (data && data["isSuccess"] === true) ? data["result"] : null;
  }
  
  public async getUserRoleFeatures(role:string) {
    const data = await this.httpClient
      .get(`${this.baseUrl}/workspace/configuration/role/${role}/features`)
      .toPromise();
    return (data && data["isSuccess"] === true) ? data["result"] : null;
  }

  public async mapFeaturesToRole(addedFeatures,removedFeatures) {
    const postdata = { addedFeatures ,removedFeatures};
    const jsonStr = JSON.stringify(postdata);
    const reply = await this.httpClient
      .post(`${this.baseUrl}/workspace/configuration/role/features`,jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
    return reply && reply["isSuccess"];
  }

  public async deleteRole(role) {
    const reply = await this.httpClient
      .delete(`${this.baseUrl}/workspace/configuration/role/${role}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
    return reply && reply["isSuccess"];
  }
  
  public async add_restriction(workspaceId,name,restrictions) {
    const postdata = { workspaceId ,name , value :restrictions};
    const jsonStr = JSON.stringify(postdata);
    const reply = await this.httpClient
      .put(`${this.baseUrl}/workspace/configuration/restriction`,jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
    return reply && reply["isSuccess"];
  }
  
  public async get_restrictions() {
    const data = await this.httpClient
      .get(`${this.baseUrl}/workspace/configuration/restrictions`)
      .toPromise();
    return (data && data["isSuccess"] === true) ? data["result"] : null;
  }
  public async loadFeatureRestrictions(featureId) {
    const data = await this.httpClient
      .get(`${this.baseUrl}/workspace/configuration/role/feature/${featureId}/restrictions`)
      .toPromise();
    return (data && data["isSuccess"] === true) ? data["result"] : null;
  }

  public async add_feature_restriction(workspaceId,name,restrictions) {
    const postdata = { workspaceId ,name , value :restrictions};
    const jsonStr = JSON.stringify(postdata);
    const reply = await this.httpClient
      .put(`${this.baseUrl}/workspace/configuration/role/features/restriction`,jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
    return reply && reply["isSuccess"];
  }
  
}
