import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-signup-console",
  templateUrl: "./signup-console.component.html",
  styleUrls: ["./signup-console.component.scss"]
})
export class SignupConsoleComponent implements OnInit {
  public routeUrl: string;
  constructor(private router: Router,private location: Location) {
  
  }

  ngOnInit() {
    this.routeUrl =this.location.path();
    if (this.routeUrl === "/signup" ||
        this.routeUrl === "/register" ||
        this.routeUrl === "/emailVerification" ||
        this.routeUrl === "/password"
      ) {
        this.router.navigate(["/signup/register"]);
      }
  }
}
