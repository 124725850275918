import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { asLiteral } from "@angular/compiler/src/render3/view/util";
import { async } from "@angular/core/testing";

@Injectable({
  providedIn: "root",
})
export class PresenceService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrlPresenceService;
  }

  public addStatusCodes = async (status_codes: object[]) => {
    const postData = {
      status_codes: status_codes // [{"status_code":"Status1"},{"status_code":"Status2"},{"status_code":"Status3"}]
    };

    const jsonStr = JSON.stringify(postData);
    const reply: any = await this.httpClient
      .post(`${this.baseUrl}/statusCodes`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      })
      .toPromise();

    if (reply && reply.isSuccess) {
      return reply.isSuccess;
    } else {
      return false;
    }
  };

  public getStatusCodes = async () => {
    const reply: any = await this.httpClient
      .get(`${this.baseUrl}/statusCodes`)
      .toPromise();
    if (reply && reply.isSuccess) {
      return reply.result;
    } else {
      return null;
    }
  };

  public deleteStatusCode = async statusCode => {
    const reply: any = await this.httpClient
      .delete(`${this.baseUrl}/statusCode/${statusCode}`)
      .toPromise();
    if (reply && reply.isSuccess) {
      return reply.isSuccess;
    } else {
      return false;
    }
  };
}
