import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StaticPageComponent } from "./landing-page/static-page/static-page.component";
import { SignInComponent } from "./landing-page/sign-in/sign-in.component";
import { ForgotPasswordComponent } from "./landing-page/forgot-password/forgot-password.component";
import { SignUpComponent } from "./signup-console/sign-up/sign-up.component";
import { EmailVerifcationComponent } from "./signup-console/email-verifcation/email-verifcation.component";
import { ChannelComponent } from "./admin-console/channel/channel.component";
import { FacebookComponent } from "./admin-console/channel/facebook/facebook.component";
import { TryNowComponent } from "./landing-page/try-now/try-now.component";

//admin console
import { ConsoleComponent } from "./admin-console/console/console.component";
import { WorkspaceComponent } from "./admin-console/workspace/workspace.component";
import { InteractionComponent } from "./admin-console/reports/historical/interaction/interaction.component";
import { DashboardComponent } from "./admin-console/reports/realtime/dashboard/dashboard.component";
import { SettingsComponent } from "./admin-console/settings/settings.component";
import { ColleaguesComponent } from "./admin-console/colleagues/invite/colleagues.component";
import { RmhandlerComponent } from "./admin-console/rmhandler/rmhandler.component";
import { SignupConsoleComponent } from './signup-console/signup-console.component';
import {UserProfileComponent} from './signup-console/user-profile/user-profile.component';
import {SignupWorkspaceComponent} from './signup-console/signup-workspace/signup-workspace.component';
import {ChangePasswordComponent} from './landing-page/change-password/change-password.component';
import {AutoLoginComponent} from './signup-console/auto-login/auto-login.component';
import {StatusCodesComponent } from './admin-console/settings/status_codes/status-codes.component';
import {LinkExpiredComponent} from './landing-page/change-password/link-expired/link-expired.component';
import { UserCreationsComponent } from './admin-console/colleagues/user-creations/user-creations.component';
import { UserCreateRouterComponent } from './admin-console/colleagues/user-create-router/user-create-router.component';
import { PasswordPolicyComponent } from './admin-console/settings/password-policy/password-policy.component';
import { MessageTemplateComponent } from './admin-console/settings/message-templates/create_template/message-template.component';
import {MessageTemplatesComponent} from './admin-console/settings/message-templates/message-templates/message-templates.component';
import {PackageBuyingComponent} from './admin-console/settings/package-buying/package-buying.component';
import {ConfigurationsRouterComponent} from './admin-console/configurations/configurations-router/configurations-router.component';
import {RoleFeatureMapComponent} from './admin-console/configurations/role-feature-map/role-feature-map.component';
import {RoleDetailComponent} from './admin-console/configurations/role-detail/role-detail.component';
import {UserRoleMapComponent} from './admin-console/configurations/user-role-map/user-role-map.component';
import { ReportsComponent } from './admin-console/reports/reports.component';
import { CdrReportComponent } from './admin-console/reports/cdr-report/cdr-report.component';
import { RestrictionMapComponent } from './admin-console/configurations/restriction-map/restriction-map.component';
import { AuthGuardService as AuthGuard  } from './service/auth/auth-guard.service';
import { from } from 'rxjs';

const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  {
    path: "home",
    component: StaticPageComponent
  },
  {
    path: "signIn",
    component: SignInComponent
  },
  {
    path: "forgotPassword",
    component: ForgotPasswordComponent
  },
  {
    path: "linkexpired",
    component: LinkExpiredComponent
  },
  {
    path: "resetpassword",
    component: ChangePasswordComponent
  },
  {
    path: "getStart",
    component: TryNowComponent
  },
  {
    path: "signup",
    component: SignupConsoleComponent,
    children: [
      {
        path: "register",
        component: SignUpComponent
      },
      {
        path: "emailVerification",
        component: EmailVerifcationComponent
      },
      {
        path: "profile",
        component: UserProfileComponent
      },
      {
        path: "workspace",
        component: SignupWorkspaceComponent
      },
      {
        path: "login",
        component: AutoLoginComponent
      }
    ]
  },
  {
    path: "console",
    component: ConsoleComponent, 
    canActivate: [AuthGuard],   
    children: [
      {
        path: "dashboard",
        component: DashboardComponent
      },
      {
        path: "reports",
        component: ReportsComponent,
        children: [
          {
            path: "report",
            component: InteractionComponent
          },
          {
            path: "dashboard",
            component: DashboardComponent
          },
          {
            path: "cdr",
            component: CdrReportComponent,            
          }
        ]
      },
      {
        path: "workspace",
        component: WorkspaceComponent
      },
      {
        path: "colleagues",
        component: UserCreateRouterComponent,
        children: [
          {
            path: "profile",
            component: UserCreationsComponent
          },
          {
            path: "invite",
            component: ColleaguesComponent
          }
        ]
      },
      {
        path: "configurations",
        component: ConfigurationsRouterComponent,
        children: [
          {
            path: "role/:role/features",
            component: RoleFeatureMapComponent
          },
          {
            path: "roledetails",
            component: RoleDetailComponent
          },
          {
            path : "role/:role/users",
            component: UserRoleMapComponent,
          },
          {
            path : "role/:role/feature/:feature/:featureID/restrictions",
            component: RestrictionMapComponent
          }
        ]
      },
      {
        path: "settings",
        component: SettingsComponent,
        children: [
          {
            path: "rm",
            component: RmhandlerComponent
          },
          {
            path: "status",
            component: StatusCodesComponent
          },
          {
            path:"passwordpolicy",
            component:PasswordPolicyComponent
          },
          {
            path:"messagetemplate",
            component:MessageTemplateComponent
          },
          {
            path:"messagetemplates",
            component:MessageTemplatesComponent
          },
          {
            path:"packages",
            component:PackageBuyingComponent
          }
        ]
      },
      {
        path: "channel",
        component: ChannelComponent,
        children: [
          {
            path: "facebook",
            component: FacebookComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
