import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class ChannelComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  //go to pages
  public async goToPage(menu) {
    switch (menu) {
      case "facebook":
        //console.log('error');
       this.router.navigate(["/console/channel/facebook"]);
        break;
      default:
        break;
    }
  }

}
