import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-link-expired",
  templateUrl: "./link-expired.component.html",
  styleUrls: ["./link-expired.component.scss"],
})
export class LinkExpiredComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}
  public async goToPage(menu) {
    switch (menu) {
      case "home":
        this.router.navigate(["/home"]);
        break;
      case "forgotPwd":
        this.router.navigate(["/forgotPassword"]);
        break;
      case "signIn":
        this.router.navigate(["/signIn"]);
        break;
      case "emailVerification":
        this.router.navigate(["/emailVerification"]);
        break;
      default:
        break;
    }
  }
}
