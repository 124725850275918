import { Component, OnInit } from "@angular/core";
import { PresenceService } from "../../../service/presence/presence.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../_alert";

@Component({
  selector: 'app-status-codes',
  templateUrl: './status-codes.component.html',
  styleUrls: ['./status-codes.component.scss']
})
export class StatusCodesComponent implements OnInit {

  public isLoading = false;
  public newStatusCode: string;
  public statusForm: FormGroup;
  public statusCodes: string[];

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private _presenceService: PresenceService
  ) {}

  ngOnInit() {
    this.statusForm = this.formBuilder.group({
      statusCode: ["", [Validators.required, Validators.minLength(2)]]
    });

    this.getStatusCode();
  }

  public async addStatus() {
    try {
      this.isLoading = true;
      const data = [{ status_code: this.newStatusCode }];
      let response = await this._presenceService.addStatusCodes(data);
      this.isLoading = false;
      if (response) {
        this.newStatusCode = "";
        this.getStatusCode();
        this.alertService.success("Status Code Successfully Save.");
        return;
      }
      this.alertService.error("Fail To Save Status Code");
    } catch (ex) {
      this.isLoading = false;
      console.error("addStatus", ex);
      this.alertService.error("Fail To Save Status Code");
    }
  }

  private async getStatusCode() {
    try {
      this.isLoading = true;
      let response = await this._presenceService.getStatusCodes();
      this.isLoading = false;
      if (response) {
        this.statusCodes = response;
        return;
      }
      this.alertService.error("Fail To Get Status Code");
    } catch (ex) {
      this.isLoading = false;
      console.error("addStatus", ex);
      this.alertService.error("Fail To Get Status Code");
    }
  }

  public async deleteStatusCode(code) {
    try {
      this.isLoading = true;
      let response = await this._presenceService.deleteStatusCode(code);
      this.isLoading = false;
      if (response) {
        this.getStatusCode();
        this.alertService.success(`Successfully Delete Status Code - ${code}`);
        return;
      }
      this.alertService.error(`Fail To Delete Status Code - ${code}`);
    } catch (ex) {
      this.isLoading = false;
      console.error("addStatus", ex);
      this.alertService.error(`Fail To Delete Status Code - ${code}`);
    }
  }
}
