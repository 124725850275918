import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { WorkspaceService } from "../../service/workspace/workspace.service";
import { EventEmitterService } from "../../service/eventEmitter/event-emitter.service";
import { UserService } from "../../service/user/user.service";
import { from } from "rxjs";
import { AlertService } from "../../_alert";
import { BasicConfig } from "../../config/constants";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import { Subscription } from "rxjs";
import { FormControl } from '@angular/forms';
import { timeout } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { PackageService } from "../../service/package/package.service";

@Component({
  selector: "app-signup-workspace",
  templateUrl: "./signup-workspace.component.html",
  styleUrls: ["./signup-workspace.component.scss"],
})
export class SignupWorkspaceComponent implements OnInit {
  public userTypes = [{type:"Agent",value:'agent'},{type:"Customer",value:'customer'},{type:"Admin",value:'admin'}];
  private userType:string ="agent";
  routeUrl: string;
  isCreateNewWorkSpace: boolean = false;
  workSpaceWizardName: number = 1;
  public workspaceName: string;
  public workspaceForm: FormGroup;
  public workspaceInviteForm: FormGroup;
  public colleaguesOne: string;
  public colleaguesTwo: string;
  public isLoading: boolean = false;
  public availableToUseAsOfficialDomain: boolean = false;
  public useAsOfficialDomain: boolean = false;
  public requestToJoinOfficialDomain: boolean = false;
  public joinToOfficialDomain: boolean = false;
  public autoAcceptJoinRequest: boolean = false;
  private savedWorkSpace: any;
  private userName: string = "";
  private password: string = "";
  public domainName: string = "";
  private existingWorkspaceId: string = "";
  private userId:string="";

  private basicConfig: any;
  public submitted = false;
  inviteSubmitted = false;
  private consoleUrl: string;
  public errorspattern : string = "Workspace name must contain alpha characters only";
  public min = 3;
  public max = 25;

  constructor(
    location: Location,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private workspaceService: WorkspaceService,
    private eventEmitterService: EventEmitterService,
    private userService: UserService,
    private alertService: AlertService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private packageService:PackageService
  ) {
    this.basicConfig = BasicConfig;
    router.events.subscribe((val) => {
      if (location.path() != "") {
        this.routeUrl = location.path();
      } else {
        this.routeUrl = "rm";
      }
    });

    this.consoleUrl = environment.TCMConsole;
  }

  ngOnInit() {
    window.addEventListener("scroll", this.scrollEvent, true);
    this.workspaceForm = this.formBuilder.group({
      workspaceName: [
        "",
        [
          Validators.required,
          Validators.pattern(this.basicConfig.domainPattern),
          Validators.minLength(this.min),
          Validators.maxLength(this.max),
        ],
      ],
    });

    this.workspaceInviteForm = this.formBuilder.group({
      colleaguesOne: [
        "",
        [
          Validators.required,
          Validators.pattern(this.basicConfig.emailPattern),
          Validators.email,
          Validators.minLength(7),
          Validators.maxLength(50),
        ],
      ],
      colleaguesTwo: [
        "",
        [
          //Validators.required,
          Validators.pattern(this.basicConfig.emailPattern),
          Validators.email,
          Validators.minLength(7),
          Validators.maxLength(50),
        ],
      ],
    });

    const profileData = window.history.state.data;
    if (profileData && profileData.emailAddress) {
      this.userName = profileData.emailAddress;
      this.password = profileData.password;
      this.userId = profileData.userId;
      this.checkDomainAvailability();
    } else {
      this.alertService.error("Missing initialization Data");
      this.sendToHome();
    }
    this.isCreateNewWorkSpace = !this.isCreateNewWorkSpace;
    this.workSpaceWizardName = 1;
  }
  
  private async checkDomainAvailability(){
    try {
    
     this.isLoading = true;
     const tokens = await Promise.all([ this.recaptchaV3Service.execute('checkDomainAvailability').toPromise(),this.recaptchaV3Service.execute('isOfficialDomain').toPromise()]);
     const details = this.userName.split("@");

     const status = await Promise.all([this.workspaceService.checkAvailability(details[1],tokens[0]), this.workspaceService.isOfficialDomain(details[1],tokens[1])]);

     if(status[0]){
      this.availableToUseAsOfficialDomain = status[0]["isSuccess"];
      /* if(status[0]["result"]){
        this.existingWorkspaceId = status[0]["result"]["workspaceid"];
      }  */     
     }
     if(status[1]){
      this.requestToJoinOfficialDomain =  true;
      this.existingWorkspaceId = status[1]["workspaceid"];  
     }
     


     if(this.requestToJoinOfficialDomain === true || this.availableToUseAsOfficialDomain === true){
        this.workspaceName = details[1];
        this.domainName = details[1];
        this.workspaceForm.get("workspaceName").setValue(this.workspaceName);
    }
      
    } catch (error) {
      console.error("Fail To Create Workspace", error);
      this.alertService.error("Fail To Check Official Domain Status");
    }finally{
      this.isLoading = false;
    }
  }

  private sendToHome() {
    setTimeout(() => {
      this.isLoading = false;
      this.router.navigate(["/home"]);
    }, 5000);
  }

  

  get f() {
    return this.workspaceForm.controls;
  }

  get fg() {
    return this.workspaceInviteForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.workspaceForm.reset();
  }

  loadWorkspaces() {
    //this.eventEmitterService.onNewWorkspaceAdded();
  }

  scrollEvent = (event: any): void => {
    const number = event.srcElement.scrollTop;
    console.log(number);
  };

  private async buyDefaultBasePackage(workspaceName,userId){
    try {
       return await this.packageService.buyDefaultBasePackage(workspaceName,userId);
    } catch (error) {
      console.error("SignupWorkspaceComponent","Fail To Add Default Package to Workspace", error);
      this.alertService.error("Fail To Add Default Package to Workspace");
      return false;
    }
  }
  
  public onKeydown(event) {
    if (event.key === "Enter") {
      if (this.workspaceName) this.saveWorkspace();
    }
  }


  public async saveWorkspace() {
    try {
      this.submitted = true;
      // stop here if form is invalid
      if (this.workspaceForm.invalid) {
        return;
      }
      this.isLoading = true;
      if(this.joinToOfficialDomain){
        let token = await this.recaptchaV3Service.execute('save_join_request').toPromise();
        let reply = await this.workspaceService.save_join_request(this.existingWorkspaceId,this.userId,this.userName,token);

        if(reply){
          this.alertService.success("Successfully send domain join request to admin");
          setTimeout(() => {
            window.open(this.consoleUrl,"_self");
          }, 3000); 
        }else{
          this.alertService.success("Fail to send domain join request to admin");
          this.isLoading = false;  
        }                
        return;
      }
      let token = await this.recaptchaV3Service.execute('checkWorkspaceNameAvailability').toPromise();
      let reply = await this.workspaceService.checkWorkspaceNameAvailability(
        this.workspaceForm.value.workspaceName,token
      );
      if (reply) {
        token = await this.recaptchaV3Service.execute('registerWorkspace').toPromise();
        reply = await this.workspaceService.registerWorkspace(
          this.workspaceForm.value.workspaceName,
          this.userName,token
        );        
        if (reply && reply["isSuccess"]) {
          token = await this.recaptchaV3Service.execute('updateUserWorkspace').toPromise();
          /* await this.workspaceService.updateUserWorkspace(this.userName,this.workspaceForm.value.workspaceName,token); 
          await this.buyDefaultBasePackage(this.workspaceForm.value.workspaceName); 
          if(this.useAsOfficialDomain){
            await this.update_official_domain(reply["result"]["workspaceid"],this.domainName,this.autoAcceptJoinRequest);
          }
          */

          await Promise.all([this.workspaceService.updateUserWorkspace(this.userName,this.workspaceForm.value.workspaceName,token),this.buyDefaultBasePackage(this.workspaceForm.value.workspaceName,this.userId),this.update_official_domain(reply["result"]["workspaceid"],this.domainName,this.autoAcceptJoinRequest)]);


          this.alertService.success("Workspace Registered Successfully");
          this.isLoading = false;
          this.savedWorkSpace = reply["result"];
          /* if(this.useAsOfficialDomain){
            setTimeout(() => {
              window.open(this.consoleUrl,"_self")
            }, 3000);
          }
          else{
            this.goToPage("nextWizard");
          } */
          this.goToPage("nextWizard");
          return;
        }
        this.isLoading = false;
        this.alertService.error("Fail To Register Workspace.");
        return;
      }
      this.isLoading = false;
      this.alertService.error("Workspace Name Already Used By Another User");
    } catch (ex) {
      this.isLoading = false;
      console.error("Fail To Create Workspace", ex);
      this.alertService.error("Fail To Create Workspace");
    }
  }

  public selectionChange = (value) => {
    this.userType = value;
  };
  
  public useAsOfficialDomainChange = () => {
    this.useAsOfficialDomain = !this.useAsOfficialDomain;
    if(this.useAsOfficialDomain === true){ 
      this.min = 3;
      this.max = 25;
      this.errorspattern = "Workspace name must be domain name";          
      this.workspaceForm.get("workspaceName").setValidators([ Validators.required, Validators.pattern(this.basicConfig.domainPattern), Validators.minLength(this.min), Validators.maxLength(this.max)]);          
      this.workspaceForm.get("workspaceName").setValue(this.workspaceName);
    }else{
      this.min = 7;
      this.max = 50;
      this.errorspattern = "Workspace name must contain alpha characters only";
      this.workspaceForm.get("workspaceName").setValidators([ Validators.required, Validators.pattern(this.basicConfig.alphabetsPattern), Validators.minLength(this.min), Validators.maxLength(this.max)]);          
    }
    this.workspaceForm.get("workspaceName").updateValueAndValidity();
  };
  
  public async SendInvitations() {
    try {
      this.inviteSubmitted = true;
      // stop here if form is invalid
      if (this.workspaceInviteForm.invalid) {
        return;
      }

      this.isLoading = true;
      const token = await this.recaptchaV3Service.execute('checkWorkspaceNameAvailability').toPromise();
      this.workspaceService.sendInvitations(
        [
          this.workspaceInviteForm.value.colleaguesOne,
          this.workspaceInviteForm.value.colleaguesTwo,
        ],
        this.userName,
        this.savedWorkSpace.workspaceid,
        this.savedWorkSpace.workSpaceName,null,this.userType,token
      );
      setTimeout(() => {
        this.isLoading = false;        

        this.router.navigate(["/signup/login"], {
          state: {
            data: { emailAddress: this.userName,password: this.password },
          },
        });

      }, 1000);
    } catch (ex) {
      this.isLoading = false;
      this.alertService.error("Fail To Send Invitations or Already invited");
      console.error(ex);
    }
  }

  private async update_official_domain(workspaceId, domain,autoAcceptRequest){
    try {
      if(this.useAsOfficialDomain){
        let token = await this.recaptchaV3Service.execute('update_official_domain').toPromise();      
        const response = await this.workspaceService.update_official_domain(
          workspaceId, domain,autoAcceptRequest,token
        );
        if(!response){
          this.alertService.error("Fail To Update Official Domain Setting");
        }
      }
      
    } catch (error) {
      this.alertService.error("Fail To Update Official Domain Setting");
      console.error(error);
    }
  }

  public async goToPage(_page) {
    switch (_page) {
      case "createNewWorkspace":
        this.isCreateNewWorkSpace = !this.isCreateNewWorkSpace;
        this.workSpaceWizardName = 1;
        break;
      case "signIn":      
        this.router.navigate(["/signup/login"], {
          state: {
            data: { emailAddress: this.userName,password: this.password },
          },
        });
        break;
      case "nextWizard":
        //1 workspace name
        //2 invite colleagues
        if (this.workSpaceWizardName == 1) {
          this.workSpaceWizardName = 2;
          return;
        }
        if (this.workSpaceWizardName == 2) {
          this.workSpaceWizardName = 1;
          return;
        }
        break;
        case "home":
          this.router.navigate(["/home"]);
          break;      
      default:
        break;
    }
  }
}
