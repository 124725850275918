import { Injectable, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";    

@Injectable({
  providedIn: "root"
})
export class EventEmitterService {
  invokeWorkspaceComponentFunction = new EventEmitter();
  subsVar: Subscription;

  constructor() {}

  onNewWorkspaceAdded(workSpace) {
    this.invokeWorkspaceComponentFunction.emit(workSpace);
  }
}
