/* export class AppConstants {
  public static get baseUrlGuardian(): string {
    return "https://tetherfi.cloud:13005" ;//"http://localhost:3005";//http://4ff14aa8.ngrok.io
  }
  public static get baseUrlUser(): string {
    return "https://tetherfi.cloud:13006";
  }
  public static get baseUrlPresenceService(): string {
    return "https://tetherfi.cloud:13009";
  }
  public static get baseUrlWorkspace(): string {
    return "https://tetherfi.cloud:13010";
  }
  public static get baseUrlClientele(): string {
    return "https://tetherfi.cloud:13011";
  }
  public static get baseUrlRelationship(): string {
    return "https://tetherfi.cloud:13014";
  }
  public static get baseUrlInteraction(): string {
    return "https://tetherfi.cloud:13021";
  }
  public static get baseUrlInsights(): string {
    return "https://tetherfi.cloud:13018";
  }
  public static get baseUserAccount(): string {
    return "https://test.tetherficloud.com:8125";
  }
  public static get TCMConsole(): string {
    return "https://tetherfi.cloud:3090";
  }
} */


export class BasicConfig{
  public static get namePattern(): string {
    return "^[a-zA-Z'-]*" ;
   /*  @NotNull (message = "First Name is required")
    @Size(min=3, max = 25, message = "First Name must be between {min} and {max} characters long.")
    @Pattern(regexp = "^[a-zA-Z'-]*", message = "First Name is invalid")
    private String firstName; */
  }
  public static get pwdPattern(): string {
    return "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!#$%&'()*+,-./:;<=>?@^_`{|}~]).{6,12}$";
  }
  public static get pwdPatternNames(): string {
    return "Maximum characters[12],Minimum characters[6],At least 1 special character,At least 1 digit,At least 1 uppercase character,At least 1 lowercase character";
    //return "Maximum characters[12],Minimum characters[6],At least 1 special character,At least 1 digit,At least 1 uppercase character,At least 1 lowercase character";
  }

  public static get mobileNumberPattern(): string {
    return "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$";
    //https://regexr.com/3c53v
    /* @NotNull(message = "Mobile Number is required")
    @Size(min=7, max = 15, message = "Mobile Number must be between {min} and {max} characters long.")
    @Pattern(regexp = "^[+][(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]", message = "Mobile Number is invalid")
    private String mobileNumber; */
  }
  public static get emailPattern(): string {
    return "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"; //'^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
    //"^(.+)@(.+)*";// "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
   /*  @NotNull(groups = OnCreate.class, message = "Email is required")
    @Size(min=7, max = 50, message = "Email must be between {min} and {max} characters long.")
    @Pattern(regexp = "^(.+)@(.+)*", message = "Email is invalid")
    @Null(groups = OnUpdate.class)
    private String emailId; */
  } 
  public static get domainPattern(): string {
    return "^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$"; 
    
  } 
  public static get alphabetsPattern(): string {
    return "^[A-Za-z]+$"; 
    
  } 
  public static get passwordLength():JSON{
    return JSON.parse(JSON.stringify({maxLength:12, minLength:6})) ; //JSON.parse('{"maxLength":12,"minLength":6}');
  }
  public static get applyPolicyForLoginPage(): Boolean{
    return false;
  }

  public static get callIds(): JSON{
    return JSON.parse(JSON.stringify({json_path:"result", json_object_name:"[cid]"}))
  }
}
