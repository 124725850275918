import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RmhandlerComponent } from "./admin-console/rmhandler/rmhandler.component";
import { LandingpageComponent } from "./admin-console/landingpage/landingpage.component";
import { ProfileManagerComponent } from "./admin-console/profile-manager/profile-manager.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { StaticPageComponent } from "./landing-page/static-page/static-page.component";
import { SignInComponent } from "./landing-page/sign-in/sign-in.component";
import { ForgotPasswordComponent } from "./landing-page/forgot-password/forgot-password.component";
import { SignUpComponent } from "./signup-console/sign-up/sign-up.component";
import { EmailVerifcationComponent } from "./signup-console/email-verifcation/email-verifcation.component";
import { ColleaguesComponent } from "./admin-console/colleagues/invite/colleagues.component";
import { WorkspaceComponent } from "./admin-console/workspace/workspace.component";
import { ConsoleComponent } from "./admin-console/console/console.component";
import { DashboardComponent } from "./admin-console/reports/realtime/dashboard/dashboard.component";
import { SettingsComponent } from "./admin-console/settings/settings.component";

import { InteractionComponent } from "./admin-console/reports/historical/interaction/interaction.component";
import { CdrComponent } from "./admin-console/reports/historical/cdr/cdr.component";
import { AgentstatusComponent } from "./admin-console/reports/realtime/agentstatus/agentstatus.component";

import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { ChannelComponent } from "./admin-console/channel/channel.component";
import { FacebookComponent } from "./admin-console/channel/facebook/facebook.component";
import { DatepickerModule, BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxSpinnerModule } from "ngx-spinner";
import { AlertModule } from "./_alert";
import { MyFirstInterceptor } from "./service/auth/RequestOptionsService";
import { from } from "rxjs";
import { SignupConsoleComponent } from "./signup-console/signup-console.component";
import { UserProfileComponent } from "./signup-console/user-profile/user-profile.component";
import { SignupWorkspaceComponent } from "./signup-console/signup-workspace/signup-workspace.component";
import { ChangePasswordComponent } from "./landing-page/change-password/change-password.component";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { AutoLoginComponent } from "./signup-console/auto-login/auto-login.component";
import { ColleagueComponent } from "./admin-console/colleagues/invite/colleague/colleague.component";
import { NumberDirective } from "./myDirective/Number/number.directive";
import { StatusCodesComponent } from "./admin-console/settings/status_codes/status-codes.component";
import { LinkExpiredComponent } from "./landing-page/change-password/link-expired/link-expired.component";
import { UserCreationsComponent } from "./admin-console/colleagues/user-creations/user-creations.component";
import { UserCreateRouterComponent } from "./admin-console/colleagues/user-create-router/user-create-router.component";
import { PasswordPolicyComponent } from "./admin-console/settings/password-policy/password-policy.component";
import { MessageTemplateComponent } from "./admin-console/settings/message-templates/create_template/message-template.component";
import { MessageTemplatesComponent } from "./admin-console/settings/message-templates/message-templates/message-templates.component";
import { TryNowComponent } from './landing-page/try-now/try-now.component';
import { PackageBuyingComponent } from './admin-console/settings/package-buying/package-buying.component';
import { RoleFeatureMapComponent } from './admin-console/configurations/role-feature-map/role-feature-map.component';
import { RoleDetailComponent } from './admin-console/configurations/role-detail/role-detail.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { ConfigurationsRouterComponent } from './admin-console/configurations/configurations-router/configurations-router.component';
import { UserRoleMapComponent } from './admin-console/configurations/user-role-map/user-role-map.component';
import { ReportsComponent } from './admin-console/reports/reports.component';
import { CdrReportComponent } from './admin-console/reports/cdr-report/cdr-report.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { RestrictionMapComponent } from './admin-console/configurations/restriction-map/restriction-map.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { JwtModule } from "@auth0/angular-jwt";

/* export function tokenGetter() {
  return sessionStorage.getItem('@@user');
} */

@NgModule({
  declarations: [
    AppComponent,
    RmhandlerComponent,
    LandingpageComponent,
    ProfileManagerComponent,
    StaticPageComponent,
    SignInComponent,
    ForgotPasswordComponent,
    EmailVerifcationComponent,
    ColleaguesComponent,
    WorkspaceComponent,
    ConsoleComponent,
    DashboardComponent,
    SettingsComponent,
    InteractionComponent,
    CdrComponent,
    AgentstatusComponent,
    ChannelComponent,
    FacebookComponent,
    SignupConsoleComponent,
    UserProfileComponent,
    SignupWorkspaceComponent,
    ChangePasswordComponent,
    AutoLoginComponent,
    ColleagueComponent,
    NumberDirective,
    StatusCodesComponent,
    LinkExpiredComponent,
    UserCreationsComponent,
    UserCreateRouterComponent,
    PasswordPolicyComponent,
    MessageTemplateComponent,
    MessageTemplatesComponent,
    SignUpComponent,
    TryNowComponent,
    PackageBuyingComponent,
    RoleFeatureMapComponent,
    RoleDetailComponent,
    ConfigurationsRouterComponent,
    UserRoleMapComponent,
    ReportsComponent,
    CdrReportComponent,
    ConfirmDialogComponent,
    RestrictionMapComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-top-right",
      progressBar: true,
    }),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgxSpinnerModule,
    AlertModule,
    RecaptchaV3Module,
    AngularDualListBoxModule,
    SimpleModalModule.forRoot({container: "modal-container"}),
    NgxDropzoneModule,
    NgxJsonViewerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: ()=>sessionStorage.getItem('@@user'),
        allowedDomains: ["example.com"],
        disallowedRoutes: ["http://example.com/examplebadroute/"],
      },
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyFirstInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6LdJX-kUAAAAAI8LjfeqTBYJNEgZPiTjExGYGwnC",
    },
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
