import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { async } from "@angular/core/testing";

@Injectable({
  providedIn: "root",
})
export class UserAuthService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUserAccount;
  }

  public saveUserProfile = async (
    profileData,
    recentToken,
    workspaceName
  ): Promise<any> => {
    try {
      const postData = {
        emailId: profileData.emailAddress,
        mobileNumber: profileData.mobileNumber,
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        userType: "0",
        password: profileData.password,
      };

      const jsonStr = JSON.stringify(postData);
      const reply = await this.httpClient
        .post(
          `${this.baseUrl}/user-auth-service/api/v1/user/account?g-recaptcha-response=${recentToken}`,
          jsonStr,
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              "tenant-token": workspaceName,
            }),
          }
        )
        .toPromise();

      return {
        isSuccess: reply && reply["status"] === 0,
        data: reply && reply["status"] === 0 ? reply["data"] : null,
      };
    } catch (error) {
      console.error("pushProfileToUserAccount", error);
      return { isSuccess: false, data: error.error.error.errorMessage };
    }
  };

  public checkUserAvailability = async (email: string, token: string) => {
    const reply = await this.httpClient
      .get(
        `${this.baseUrl}/user-auth-service/api/v1/user/verify/${email}?g-recaptcha-response=${token}`
      )
      .toPromise();

    const status = reply && reply["status"] === 0;
    return {
      isSuccess: status,
      data: status ? "" : reply["error"]["errorMessage"],
    };
    /*  try {
      const reply = await this.httpClient
        .get(`${this.baseUrl}/user-auth-service/api/v1/user/verify/${email}`)
        .toPromise();

      const status = reply && reply["status"] === 0;
      return {
        isSuccess: status,
        data: status ? "" : reply["error"]["errorMessage"],
      };
    } catch (error) {
      console.error("checkUserAvailability", error);
      return { isSuccess: false, data: "Fail To Validate Request" };
    } */
  };

  public getProfiles = async (userIds: string[], workspaceName: string) => {
    try {
      const jsonStr = JSON.stringify(userIds);
      const reply = await this.httpClient
        .post(
          `${this.baseUrl}/user-auth-service/api/v1/user/accounts`,
          jsonStr,
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              "tenant-token": workspaceName,
            }),
          }
        )
        .toPromise();
      if (reply && reply["status"] === 0) {
        return reply["data"]["users"];
      } else {
        return null;
      }
    } catch (error) {
      console.error("checkUserAvailability", error);
      return null;
    }
  };

  public getMyProfile = async (email: string, token: string) => {
    try {
      const reply = await this.httpClient
      .get(
        `${this.baseUrl}/user-auth-service/api/v1/user/account/user-name/${email}?g-recaptcha-response=${token}`
      )
      .toPromise();

    const status = reply && reply["status"] === 0;
    return {
      isSuccess: status,
      result: status ? reply["data"]:null,
    };
    } catch (error) {
      console.error("getMyProfile", error);
      return {
        isSuccess: false,
        result: null,
      };
    }
    
  };
}
