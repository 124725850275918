import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { RelationshipService } from "../../service/relationship/relationship.service";

@Component({
  selector: "app-profile-manager",
  templateUrl: "./profile-manager.component.html",
  styleUrls: ["./profile-manager.component.scss"]
})
export class ProfileManagerComponent implements OnInit {
  public relationships: Array<any> = [];
  constructor(private _relationshipService: RelationshipService) {}

  ngOnInit() {
    this.loadCustomerType();
  }

  public async loadCustomerType() {
    let response = await this._relationshipService.getCustomers();
    if (response["isSuccess"]) {
      
      Object.values(response["result"]).forEach(value =>{
        this.relationships.push(value);
     });
    }
  }
}
