import { Component, OnInit } from "@angular/core";
import { async } from '@angular/core/testing';
import { WorkspaceService } from "../../../service/workspace/workspace.service";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import { LoginHandlerService } from "../../../service/auth/login-handler.service";
import { AlertService } from "../../../_alert";

@Component({
  selector: "app-password-policy",
  templateUrl: "./password-policy.component.html",
  styleUrls: ["./password-policy.component.scss"],
})
export class PasswordPolicyComponent implements OnInit {

  public regex = new Array("(?=.*[A-Z])","(?=.*[a-z])","(?=.*[0-9])","(?=.*[!#$%&'()*+,-./:;<=>?@^_`{|}~])",".{6,12}$");
  public policyNames = new Array("Maximum characters[12].","Minimum characters[6].","At least 1 special character.","At least 1 digit.","At least 1 uppercase character.","At least 1 lowercase character.");
  /*  (?=.*[a-z])        // use positive look ahead to see if at least one lower case letter exists
(?=.*[A-Z])        // use positive look ahead to see if at least one upper case letter exists
(?=.*[0-9])           // use positive look ahead to see if at least one digit exists
(?=.*[!#$%&'()*+,-./:;<=>?@^_`{|}~])        // use positive look ahead to see if at least one non-word character exists */

  public oneUppercase:boolean=true;
  public oneLowercase:boolean=true;
  public oneDigit:boolean=true;
  public onsSpecialCharacter:boolean=true;
  public minLength:number=6;
  public maxLength:number=12;
  public minLengthOld:number=6;
  public maxLengthOld:number=12;
  public forcePasswordChange:number=0;
  public oldPasswords:number=0;

  public loading:boolean=false;
  

  constructor(private alertService: AlertService,private workspaceService: WorkspaceService,private recaptchaV3Service: ReCaptchaV3Service,private loginHandlerService: LoginHandlerService) {}

  ngOnInit() {
    const passwordPolicy = this.loginHandlerService.GetPasswordPolicy();
    if(passwordPolicy){
      
    }
  }

  public checkUppercase = (event) => {
    if (event.target.checked) {
      this.regex.unshift("(?=.*[A-Z])"); //Uppercase Alphabet.
      this.policyNames.push("At least 1 uppercase character.");
    } else {
      const index = this.regex.indexOf("(?=.*[A-Z])");
      if (index > -1) {
        this.regex.splice(index, 1);
      }

      const indexN = this.policyNames.indexOf("At least 1 uppercase character.");
      if (indexN > -1) {
        this.policyNames.splice(indexN, 1);
      }
    }
  };

  public checkLowercase = (event) => {
    if (event.target.checked) {
      this.regex.unshift("(?=.*[a-z])"); //Lowercase Alphabet.
      this.policyNames.push("At least 1 lowercase character.");
    } else {
      const index = this.regex.indexOf("(?=.*[a-z])");
      if (index > -1) {
        this.regex.splice(index, 1);
      }

      const indexN = this.policyNames.indexOf("At least 1 lowercase character.");
      if (indexN > -1) {
        this.policyNames.splice(indexN, 1);
      }
    }
  };

  public checkDigit = (event) => {
    if (event.target.checked) {
      this.regex.unshift("(?=.*[0-9])"); //Digit.
      this.policyNames.push("At least 1 digit.");
    } else {
      const index = this.regex.indexOf("(?=.*[0-9])");
      if (index > -1) {
        this.regex.splice(index, 1);
      }

      const indexN = this.policyNames.indexOf("At least 1 digit.");
      if (indexN > -1) {
        this.policyNames.splice(indexN, 1);
      }
    }
  };

  public checkSpecialCharacter = (event) => {
    if (event.target.checked) {
      this.regex.unshift("(?=.*[!#$%&'()*+,-./:;<=>?@^_`{|}~])"); //Special Character.
      this.policyNames.push("At least 1 special character.");
    } else {
      const index = this.regex.indexOf("(?=.*[!#$%&'()*+,-./:;<=>?@^_`{|}~])");
      if (index > -1) {
        this.regex.splice(index, 1);
      }

      const indexN = this.policyNames.indexOf("At least 1 special character.");
      if (indexN > -1) {
        this.policyNames.splice(indexN, 1);
      }

    }
  };
  
  public minValue = (event) => {
    this.minLength = event.target.value;
    this.regex.pop();
    this.regex.push(`.{${this.minLength},${this.maxLength}}$`);

    const indexN = this.policyNames.indexOf(`Minimum characters[${this.minLengthOld}].`);
      if (indexN > -1) {
        this.policyNames.splice(indexN, 1);
      }
    this.policyNames.push(`Minimum characters[${this.minLength}].`);
    this.minLengthOld = event.target.value;
  };

  public maxValue = (event) => {
    this.maxLength = event.target.value;
    this.regex.pop();
    this.regex.push(`.{${this.minLength},${this.maxLength}}$`);

    const indexN = this.policyNames.indexOf(`Maximum characters[${this.maxLengthOld}].`);
      if (indexN > -1) {
        this.policyNames.splice(indexN, 1);
      }
    this.policyNames.push(`Maximum characters[${this.maxLength}].`);
    this.maxLengthOld = event.target.value;
  };

  public forcePasswordChanged = (event) => {    
    this.forcePasswordChange = event.target.value;
  };
  public oldPasswordCount = (event) => {    
    this.oldPasswords = event.target.value;
  };

  public generateRegex= async()=>{
    try {
      this.loading = true;
      this.regex.unshift("^");
    console.log(this.regex.join(""));

    let token = await this.recaptchaV3Service.execute('checkWorkspaceNameAvailability').toPromise();
    let reply = await this.workspaceService.savePasswordPolicy(
      this.loginHandlerService.GetWorkspace().workspaceid,this.regex.join(""),this.policyNames.join(","),this.forcePasswordChange,this.oldPasswords,this.maxLength,this.minLength
    );
    if(reply){
      this.alertService.success("Password policy successfully saved.");
      this.loginHandlerService.SetPasswordPolicy(this.regex.join(""));
      this.loginHandlerService.SetPasswordPolicyNames(this.policyNames.join(","));
      return;
    }
    this.alertService.error("Fail to save password policy");
    } catch (error) {
      console.error("generateRegex",error);
      this.alertService.error("Fail to save password policy");
    }
    finally{
      this.regex.splice(0, 1);
      this.loading=false;
    }
    
  }
}
