import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RelationshipService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrlRelationship;
  }
  public getCustomers() {
    return this.httpClient
      .get(`${this.baseUrl}/relationship/managers`)
      .toPromise();
  }

  public getCustomerTypes() {
    return this.httpClient
      .get(`${this.baseUrl}/relationship/customerType`)
      .toPromise();
  }

  public getCustomersByManager(managerId) {
    return this.httpClient
      .get(`${this.baseUrl}/relationship/manager/${managerId}`)
      .toPromise();
  }

  public removeRelationship(managerId, customerId) {
    return this.httpClient
      .delete(
        `${this.baseUrl}/relationship/manager/${managerId}/customer/${customerId}`
      )
      .toPromise();
  }

  public saveRelationship(
    manager: string,
    customer: Array<object>,
    customerType: string
  ) {
    const postData = {
      manager: manager,
      customers: customer,
      //customerType: customerType
    };

    const jsonStr = JSON.stringify(postData);
    return this.httpClient
      .post(`${this.baseUrl}/relationship`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
  }
}
