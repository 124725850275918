import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GuardianService } from "../../service/guardian/guardian.service";
import { UserAuthService } from "../../service/userAuth/user-auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_alert";
import { BasicConfig } from "../../config/constants";
import { async } from "@angular/core/testing";
import { NgxSpinnerService } from "ngx-spinner";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import { WorkspaceService } from "../../service/workspace/workspace.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  public spinnerMsg: string = "Validating Email...";
  public displayName: string;
  public userType: string = "0";
  public firstName: string = "";
  public lastName: string = "";
  public mobileNumber: string = "";
  public emailAddress: string = "";
  public password: string = "";
  public confirmPassword: string = "";
  public isUserExists: any = false;
  public isLoading: boolean = false;
  public passwordStrength = {
    float: "left",
    width: "100px",
    height: "25px",
    "margin-left": "5px",
    "background-color": "white",
  };
  private registerForm: FormGroup;
  private basicConfig: any;
  public submitted = false;
  public requestToJoinOfficialDomain: boolean = false;
  private existingWorkspaceId:string = null;
  private workspaceName: string = "";
  public domainName: string = "";
  private userId: string = null;
  private consoleUrl: string;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private userAuthService: UserAuthService,
    private guardianService: GuardianService,
    private alertService: AlertService,
    private recaptchaV3Service: ReCaptchaV3Service,private workspaceService: WorkspaceService,
  ) {
    this.basicConfig = BasicConfig;
    this.consoleUrl = environment.TCMConsole;
  }

  ngOnInit() {
    if (this.route.params) {
      this.route.params.subscribe((params) => {
        this.emailAddress = params.email;
      });
      if (this.emailAddress)
        this.isEmailExisting(this.emailAddress.toLowerCase());
    }
  }

  public async goToPage(menu) {
    switch (menu) {
      case "home":
        this.router.navigate(["/home"]);
        break;
      case "forgotPwd":
        this.router.navigate(["/forgotPassword"]);
        break;
      case "signIn":
        this.router.navigate(["/signIn"]);
        break;
      case "emailVerification":
        this.router.navigate(["/emailVerification"]);
        break;
      case "userExists":
        this.isUserExists = !this.isUserExists;
        break;
      case "signWithParam":
        await this.router.navigate(["/signIn"], {
          state: {
            data: {
              emailAddress: this.emailAddress,
            },
          },
        });
        break;
      default:
        break;
    }
  }

  private async isEmailExisting(emailAddress: string) {
    try {
      this.spinner.show();
      const token = await this.recaptchaV3Service.execute('isEmailExisting').toPromise();
      const reply = await this.userAuthService.checkUserAvailability(
        emailAddress,token
      );
      if (!reply.isSuccess) {        
        await this.checkDomainAvailability();
        this.spinnerMsg = `${reply.data}`;
        this.spinner.hide();
        this.isUserExists = true;
      } 
      else {
        if (await this.sendVerificationCode(emailAddress.toLowerCase())) {
          this.spinner.hide();

          await this.router.navigate(["/signup/emailVerification"], {
            state: {
              data: {
                emailAddress: emailAddress.toLowerCase(),
              },
            },
          });
        } else {
          this.spinnerMsg = `Fail To Send Verification Code ${emailAddress}`;
          this.alertService.error(this.spinnerMsg);
          console.error("Signup", this.spinnerMsg);
          this.isLoading = false;
          this.sendToHome();
        }
      }
    } catch (e) {
      console.error(`Fail To validate Email Address ${emailAddress}`);
      this.spinnerMsg = `Fail To validate Email Address ${emailAddress}`;
      this.alertService.error(this.spinnerMsg);
      this.sendToHome();
    }
  }
  private sendToHome() {
    setTimeout(() => {
      this.router.navigate(["/home"]);
    }, 5000);
  }
  private async sendVerificationCode(displayName: string) {
    try {
      const token = await this.recaptchaV3Service.execute('sendVerificationCode').toPromise();
      return await this.guardianService.sendVerificationCode(
        displayName,
        token
      );
    } catch (e) {
      console.error(`Fail To Send Verification Code ${displayName}`);
      return false;
    }
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }


  private async checkDomainAvailability(){
    try {
    
     this.isLoading = true;
     const tokens = await Promise.all([ this.recaptchaV3Service.execute('isOfficialDomain').toPromise(),this.recaptchaV3Service.execute('getMyProfile').toPromise(),this.recaptchaV3Service.execute('getWorkspaceByUserName').toPromise()]);
     const details = this.emailAddress.split("@");
    
    const status = await Promise.all([this.workspaceService.isOfficialDomain(details[1],tokens[0]),this.userAuthService.getMyProfile(this.emailAddress,tokens[1]),this.workspaceService.getWorkspaceByUserName(this.emailAddress,tokens[2])]);

     if(status[0] !== null){
        this.requestToJoinOfficialDomain =  true;
        this.existingWorkspaceId = status[0]["workspaceid"];   
        this.workspaceName = details[1];
        this.domainName = details[1];

        if(status[1] && status[1]["result"]){
          this.userId = status[1]["result"].userId;
        }

        if(status[2]){          
          status[2].map((item)=>{            
            if(item.workspaceid === this.existingWorkspaceId){
              this.requestToJoinOfficialDomain = false;
            }
          });
        }
      }

      
    } catch (error) {
      console.error("Fail To Create Workspace", error);
      this.alertService.error("Fail To Check Official Domain Status");
    }finally{
      this.isLoading = false;
    }
  }

  public async joinToDomain(){
    try {
      if(this.requestToJoinOfficialDomain){
        if(this.existingWorkspaceId === null){
          this.alertService.error("Fail to find workspace data");
          return;
        }
        if(this.userId === null){
          this.alertService.error("Fail to find user profile data");
          return;
        }

        let token = await this.recaptchaV3Service.execute('save_join_request').toPromise();
        let reply = await this.workspaceService.save_join_request(this.existingWorkspaceId,this.userId,this.emailAddress,token);

        if(reply){
          this.alertService.success("Successfully send domain join request to admin");
          setTimeout(() => {
            window.open(this.consoleUrl,"_self")
          }, 3000);
          
          //this.sendToHome();          
        }else{
          this.alertService.error("Fail to send domain join request to admin");
          this.isLoading = false;  
        }  
      }
    } catch (error) {
      this.alertService.error("Fail to send domain join request to admin");
      this.isLoading = false;  
    }
  }
}
