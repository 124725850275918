import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { async } from "q";

@Injectable({
  providedIn: "root",
})
export class ClienteleService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    //this.baseUrl = environment.baseUrlClientele;
    this.baseUrl = environment.baseUserAccount;
  }

  /* async getCustomerByPage(page, size) {
    return this.httpClient
      .get(`${this.baseUrl}/clientele/users/${page}/${size}`)
      .toPromise();
  }
  async getCustomers(): Promise<object> {
    return new Promise(async (resolve, reject) => {
      try {
        let loadCustomers = true;
        let pageNo = 1;
        let customers = [];
        while (loadCustomers) {
          const response = await this.getCustomerByPage(pageNo, 100);
          if (!response["isSuccess"] || response["result"] == null) {
            loadCustomers = false;
            resolve(customers);
          }
          pageNo++;
          customers.push(
            response["result"].map(function(i) {
              i["name"] = `${i["firstName"]} ${i["lastName"]}`;
              return i;
            })
          );
        }
      } catch (e) {
        reject(e);
      }
    });
  } */

  async getCustomerByPage(page, size) {
    return this.httpClient
      .get(
        `${this.baseUrl}/user-auth-service/api/v1/user/accounts?pageNo=${page}&pageSize=${size}`
      )
      .toPromise();
  }
  async getCustomers(): Promise<object> {
    return new Promise(async (resolve, reject) => {
      try {
        let loadCustomers = true;
        let pageNo = 1;
        let customers = [];
        while (loadCustomers) {
          const response = await this.getCustomerByPage(pageNo, 100);
          if (response["data"]["users"].length == 0) {
            loadCustomers = false;
            resolve(customers);
            return;
          }
          pageNo++;
          //      const arr = [1,2,3,4,5];
          //const newArray = arr.filter(i => i%2 == 0);

          customers.push(
            ...response["data"]["users"].filter(
              (i) => i.userType === "Customer"
            )
          );
        }
      } catch (e) {
        reject(e);
      }
    });
  }
}
