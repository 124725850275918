import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class InsightsService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrlInsights;
  }

  public async GetInsights(
    workspace: string,
    metric: string,
    type: string,
    fromDate: string,
    toDate: string,
    queryParameters: string
  ) {
    let url = `${this.baseUrl}/API/Insights/${workspace}/${fromDate}/${toDate}/${metric}/${type}`;
    if (queryParameters) {
      url = `${url}?${queryParameters}`;
    }
    const reply: any = await this.httpClient.get(url).toPromise();

    if (reply && reply.isSuccess) {
      return reply.result;
    } else {
      return null;
    }
  }
}
