import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-configurations-router',
  templateUrl: './configurations-router.component.html',
  styleUrls: ['./configurations-router.component.scss']
})
export class ConfigurationsRouterComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.navigate(["/console/configurations/roledetails"]);
    //this.router.navigate(["console/configurations/feature/feature1/restrictions"]);
  }

}
