import { Component, OnInit, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GuardianService } from "../../service/guardian/guardian.service";
import { UserService } from "../../service/user/user.service";
import { UserAuthService } from "../../service/userAuth/user-auth.service";
import { AlertService } from "../../_alert";

import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import { Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-email-verifcation",
  templateUrl: "./email-verifcation.component.html",
  styleUrls: ["./email-verifcation.component.scss"],
})
export class EmailVerifcationComponent implements OnInit {
  public validationCode: string;
  public profileData = null;
  private resendCount: number = 0;
  public maskEmail: string = "";
  public isLoading: boolean = false;
  constructor(
    public router: Router,
    private guardianService: GuardianService,
    private userService: UserService,
    private alertService: AlertService,
    private userAuthService: UserAuthService,private spinner: NgxSpinnerService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}
  @HostListener("paste", ["$event"]) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener("copy", ["$event"]) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener("cut", ["$event"]) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

  ngOnInit() {
    this.profileData = window.history.state.data;
    if (this.profileData == null) {
      this.alertService.error("No profile data");
      this.router.navigate(["/home"]);
      return;
    }
    this.maskEmail = this.censorEmail(this.profileData.emailAddress);
  }
 
  
  private censorWord = (str) => {
    // return str[0] + "*".repeat(str.length - 2) + str.slice(-1); https://dev.azure.com/TetherfiCloudTeam/CloudSolution/_workitems/edit/739/
    return "*".repeat(str.length - 2) + str.slice(-2);
  };

  private censorEmail = (email) => {
    var arr = email.split("@");
    return `${this.censorWord(arr[0])}@${arr[1]}`;
  };
  // console.log(censorEmail("jack.dawson@gmail.com"));

  public async resendCode() {
    this.resendCount++;
    try {
      this.isLoading = true;
      if (this.resendCount >= 3) {
        this.alertService.error("Resend try count exceeded");
        setTimeout(() => {
          this.router.navigate(["/home"]);
        }, 3000);
        return;
      }
      const token = await this.recaptchaV3Service.execute('sendVerificationCode').toPromise();
      const reply = await this.guardianService.sendVerificationCode(
        this.profileData.emailAddress,token
      );
      if (reply) {
        this.alertService.success(
          `Resent otp to email id ${this.profileData.emailAddress}`
        );
      } else {
        this.alertService.error(
          `Fail to resent otp to ${this.profileData.emailAddress}`
        );
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.alertService.error(
        `Fail to resent otp to ${this.profileData.emailAddress}`
      );
      console.error("resendCode", error);
      return false;
    }
  }

  public async validateCode() {
    try {
      if (this.validationCode.length == 5) {
        this.isLoading = true;
        const token = await this.recaptchaV3Service.execute('validateCode').toPromise();
        const reply = await this.guardianService.validateCode(
          this.validationCode,
          this.profileData.emailAddress,token
        );
        if (reply) {
          this.router.navigate(["/signup/profile"], {
            state: {
              data: { emailAddress: this.profileData.emailAddress },
            },
          });
          this.isLoading = false;
          return;
        } else {
          this.isLoading = false;
          const error_msg = "Invalid Code Or Token Expired";
          console.error(error_msg);
          this.alertService.error(error_msg);
          this.validationCode = "";
        }
      }
    } catch (ex) {
      this.isLoading = false;
      this.alertService.error("Fail To Validate Code");
      console.error(ex);
    }
  }
}
