import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class InteractionService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrlInteraction;
  }

  public async getInteractions(
    workspace: string,
    channel: string,
    type: number,
    page: number,
    fromDate: Date,
    toDate: Date
  ) {
    //API/Reports/Interaction?workspace=tetherfisl&from_date=2011-08-12T20:17:46.384Z&to_date=2020-08-12T20:17:46.384Z&limit=10&page=1&channel=FACEBOOK&interaction_type=2
    let url = `${this.baseUrl}/API/Reports/Interaction?workspace=${workspace}&from_date=${fromDate}&to_date=${toDate}&limit=100&page=${page}`;
    if (channel !== "ALL") {
      url = `${url}&channel=${channel}`;
    }
    if (type > 0) {
      url = `${url}&interaction_type=${type}`;
    }
    const reply: any = await this.httpClient.get(url).toPromise();

    if (reply && reply.isSuccess) {
      return reply.result;
    } else {
      return null;
    }
  }
}
