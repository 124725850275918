import { Component, ViewChild, ElementRef, OnInit } from "@angular/core";
import { TemplatesService } from "../../../../service/templates/templates.service";
import { LoginHandlerService } from "../../../../service/auth/login-handler.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { from } from "rxjs";
import { AlertService } from "../../../../_alert";
import { BasicConfig } from "../../../../config/constants";
import { ActivatedRoute, Router } from "@angular/router";
import { async } from "@angular/core/testing";
import { FormControl } from '@angular/forms';

@Component({
  selector: "app-message-templates",
  templateUrl: "./message-template.component.html",
  styleUrls: ["./message-template.component.scss"],
})
export class MessageTemplateComponent implements OnInit {
  @ViewChild("r", null) r: ElementRef;
  data = "Angular 6";

  public templateTypes = [
    { type: "Template", value: "TEMPLATE" },
    { type: "Canned Admin", value: "CANNED_ADMIN" },
  ];

  public referenceValueTypes = [
    { type: "Customer Profile", value: "CUSTOMER_PROFILE" },
  ];

  public templateValues = null;
  public workspaceInviteForm: FormGroup;

  constructor(
    private templatesService: TemplatesService,
    private formBuilder: FormBuilder,
    private loginHandlerService: LoginHandlerService,
    private alertService: AlertService,
    private router: Router
  ) {}

  public isLoading: boolean = false;
  public selectedTemplate = "TEMPLATE";
  public referenceValueType = null;
  public submitted = false;
  public templateMap: any = {};

  ngOnInit() {  
    this.loadInitialData();  
    this.workspaceInviteForm = this.formBuilder.group({
      TemplateName: [
        "",
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(50),
        ],
      ],
      Description: [
        "",
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(50),
        ],
      ],
      MyTemplateMessage: [
        "",
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(500),
        ],
      ]      
    });
  }

  get f() {
    return this.workspaceInviteForm.controls;
  }

  private loadInitialData= async ()=>{
    await this.GetTemplateValueMeta();
    await this.GetTemplateValues();
  }
  private onReset() {
    this.submitted = false;
    this.workspaceInviteForm.reset();
  }
  
  private GetTemplateValueMeta = async () => {
    try {
      this.isLoading = true;
      const response = await this.templatesService.GetTemplateValueMeta();
      if(response){
        this.referenceValueTypes = [];
        Object.keys(response).map((key)=>{
          this.referenceValueTypes.push({ type: key, value: key });
          this.referenceValueType = key;            
        })
      }      
    } catch (error) {
      console.error("message-template", "GetTemplateValueMeta", error);
    } finally {
      this.isLoading = false;
    }
  };

  private GetTemplateValues = async () => {
    try {
      this.isLoading = true;
      this.templateValues = await this.templatesService.GetTemplateValues(
        this.referenceValueType
      );
      console.log(JSON.stringify(this.templateValues));
    } catch (error) {
      console.error("message-template", "GetTemplateValues", error);
    } finally {
      this.isLoading = false;
    }
  };

  public templateTypesChange = (value) => {
    this.selectedTemplate = value;
    if(value==="CANNED_ADMIN"){
      this.workspaceInviteForm.addControl('Trigger', new FormControl('', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(500),
      ]));
    }else{
      this.workspaceInviteForm.removeControl('Trigger');
    }
  };

  public referenceValueTypesChange = (value) => {
    this.referenceValueType = value;
    this.GetTemplateValues();
  };

  public saveTemplate = async () => {
    try {
      this.isLoading = true;
      this.submitted = true;
      if (this.workspaceInviteForm.invalid) {
        return;
      }
      let templateMessage = this.workspaceInviteForm.value.MyTemplateMessage;
      Object.keys(this.templateMap).map((key) => {
        templateMessage = templateMessage.replaceAll(
          key,
          this.templateMap[key]
        );       
      });
      let postData = {
        TemplateName: this.workspaceInviteForm.value.TemplateName,
        Description: this.workspaceInviteForm.value.Description,
        TemplateMessage: templateMessage,
        TemplateType: this.selectedTemplate,
        Trigger: this.workspaceInviteForm.value.Trigger,
      };

      const reply = await this.templatesService.SaveTemplate(postData);
      if (reply && reply["isSuccess"]) {
        
        if(await this.templatesService.AssignMultipleValuesToTemplate(reply["result"]["Uuid"],Object.values(this.templateMap))){
          this.alertService.success("Template data successfully saved.");
          this.onReset();
        }
        else{
          console.error("message-template", "Fail to assign template values");
          this.alertService.error(`Fail to assign template values`);
        }       
      } else {
        console.error("message-template", "Fail to save template");
        this.alertService.error(`Fail to save template`);
      }
    } catch (error) {
      console.error("message-template", "saveTemplate", error);
    } finally {
      this.isLoading = false;
    }
  };
  public addProfileFelid = (e) => {
    try {
      var startPos = this.r.nativeElement.selectionStart;
      this.r.nativeElement.focus();
      /* this.r.nativeElement.value =
      this.r.nativeElement.value.substr(
        0,
        this.r.nativeElement.selectionStart
      ) +
      e +
      this.r.nativeElement.value.substr(
        this.r.nativeElement.selectionStart,
        this.r.nativeElement.value.length
      ); */
      const msgPartOne = this.r.nativeElement.value.substr(
        0,
        this.r.nativeElement.selectionStart
      );
      const msgPartTwo = this.r.nativeElement.value.substr(
        this.r.nativeElement.selectionStart,
        this.r.nativeElement.value.length
      );
      this.r.nativeElement.value = `${msgPartOne} {{${this.referenceValueType}.${e.Label}}} ${msgPartTwo}`;
      //this.r.nativeElement.selectionStart= startPos;
      this.r.nativeElement.selectionEnd = this.r.nativeElement.selectionEnd + 1;
      this.r.nativeElement.focus();
      this.workspaceInviteForm
        .get("MyTemplateMessage")
        .setValue(this.r.nativeElement.value);
      this.templateMap[`${this.referenceValueType}.${e.Label}`] = e.Uuid;
    } catch (error) {
      console.error("message-template", "addProfileFelid", error);
    }
  };
}
