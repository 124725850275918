import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CallReportsService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrlReportsApi;
  }

  public async getConversationsCount(workspaceId) {
    const reply = await this.httpClient.get(`${this.baseUrl}/reports/${workspaceId}/count`).toPromise();
    return reply && reply["isSuccess"] ? reply["result"] : 0;
  }


  public async getConversations(page: number, pageSize:number = 1000,startDateTime,endDateTime,callIds,workspaceId) {

    const qdString = `?start=${startDateTime}&end=${endDateTime}&callIds=${callIds}`;

    const reply = await this.httpClient.get(`${this.baseUrl}/reports/${workspaceId}/${page}/${pageSize}${qdString}`).toPromise();
    return reply && reply["isSuccess"] ? reply["result"] : [];
  }
}
