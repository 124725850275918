import {Component, OnInit, isDevMode} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginHandlerService} from '../../service/auth/login-handler.service';
import {AlertService} from '../../_alert';

import {ToastrService} from 'ngx-toastr';
import {BasicConfig} from '../../config/constants';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public password: string;
  public emailAddress: string;
  public signInForm: FormGroup;
  public basicConfig: any;
  public isLoading: boolean = false;
  public submitted = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private loginHandlerService: LoginHandlerService,
    private toastr: ToastrService,
    private alertService: AlertService
  ) {
    this.basicConfig = BasicConfig;
  }

  ngOnInit() {

    if (isDevMode()) {
      console.log('--------------- 👋 Development! 👋 ---------------');
    } else {
      console.log('---------------  💪 Production! 💪 ---------------');
    }
    
    if(this.basicConfig.applyPolicyForLoginPage){
      this.signInForm =  this.formBuilder.group({
        emailAddress: [
          '',
          [
            Validators.required,
            Validators.pattern(this.basicConfig.emailPattern),
            Validators.email,
            Validators.minLength(7),
            Validators.maxLength(50),
          ],
        ],        
        password: [
          '', 
          [
            Validators.required, 
            Validators.minLength(this.basicConfig.passwordLength.minLength),
            Validators.maxLength(this.basicConfig.passwordLength.maxLength),
            Validators.pattern(this.basicConfig.pwdPattern)
          ]
        ],
      });
    }else{
      this.signInForm =  this.formBuilder.group({
        emailAddress: [
          '',
          [
            Validators.required,
            Validators.pattern(this.basicConfig.emailPattern),
            Validators.email,
            Validators.minLength(7),
            Validators.maxLength(50),
          ],
        ],        
        password: [
          '', 
          [
            Validators.required, 
          ]
        ],
      });
    }
    

    const profileData = window.history.state.data;
    if (profileData && profileData.emailAddress) {
      this.signInForm.get('emailAddress').setValue(profileData.emailAddress);
    }
  }

  get f() {
    return this.signInForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.signInForm.reset();
  }

  public async login() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.signInForm.invalid) {
      return;
    }
    this.isLoading = true;
    try {
      const response = await this.loginHandlerService.Login(
        this.signInForm.value.emailAddress,
        this.signInForm.value.password
      );
      if (response.isSuccess) {
        await this.router.navigate(['/console/workspace']);
      } else {
        this.alertService.error('Please check your email or password.');
      }
      this.isLoading = false;
    } catch (ex) {
      let msg = 'Please check your email or password.';
      if (ex.status === 403) {
        msg = 'You don\'t have access to admin console, please contact system administrator';
      }
      this.alertService.error(msg);
      console.error('Login', ex);
      this.isLoading = false;
    }
  }

  public onKeydown(event) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  public async goToPage(menu) {
    switch (menu) {
      case 'home':
        this.router.navigate(['/home']);
        break;
      case 'createAccount':
        this.router.navigate(['/createAccount']);
        break;
      case 'forgotPassword':
        this.router.navigate(['/forgotPassword']);
        break;
      case 'signUp':
        this.router.navigate(['/getStart']);
        break;
      default:
        break;
    }
  }
}
