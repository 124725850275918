import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GuardianService } from "../../service/guardian/guardian.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_alert";
import { BasicConfig } from "../../config/constants";
import { async } from "@angular/core/testing";
import { NgxSpinnerService } from "ngx-spinner";
import { UserAuthService } from "../../service/userAuth/user-auth.service";
import { LoginHandlerService } from "../../service/auth/login-handler.service";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import { Subscription } from "rxjs";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  public emailAddress: string = "";
  public password: string = "";
  public confirmPassword: string = "";
  public token: string = "";
  public isLoading: boolean = false;

  public setPasswordForm: FormGroup;
  public basicConfig: any;
  public submitted = false;

  private subscription: Subscription;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private guardianService: GuardianService,
    private alertService: AlertService,
    private loginHandlerService: LoginHandlerService,
    private userAuthService: UserAuthService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.basicConfig = BasicConfig;
  }

  ngOnInit() {
    this.setPasswordForm = this.formBuilder.group(
      {
        password: [
          "", 
          [
            Validators.required, 
            Validators.minLength(this.basicConfig.passwordLength.minLength),
            Validators.maxLength(this.basicConfig.passwordLength.maxLength),
            Validators.pattern(this.basicConfig.pwdPattern)
          ]
        ],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: this.MustMatch("password", "confirmPassword"),
      }
    );

    this.token = this.route.snapshot.queryParamMap.get("token");
    this.emailAddress = this.route.snapshot.queryParamMap.get("username");
    this.validateRequest()
    
}

public OnDestroy() {
  console.log("userProfile","ngOnDestroy");
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
}
ngOnDestroy() {
  console.log("userProfile","ngOnDestroy");
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
}
  get f() {
    return this.setPasswordForm.controls;
  }

  private validateRequest = async () => {
    const rToken = await this.recaptchaV3Service.execute('validateRequest').toPromise();
    if(! await this.guardianService.changePasswordRequestValidation(
      this.token,
      this.emailAddress,rToken
    )){
    //  this.router.navigate(["/linkexpired"]);
    }
  };
  private MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  onReset() {
    this.submitted = false;
    this.setPasswordForm.reset();
  }

  private loginToSystem = async () => {
    try {
      await this.loginHandlerService.Login(
        this.emailAddress.toLowerCase(),
        this.setPasswordForm.value.password
      );

      setTimeout(() => {
        this.isLoading = false;
        this.alertService.success("Password Update Successfully");
        this.router.navigate(["/console/workspace"]);
      }, 3000);
    } catch (error) {
      this.isLoading = false;
      this.router.navigate(["/signIn"]);
      console.error("loginToSystem", error);
    }
  };

  public setPassword = async () => {
    try {
      this.submitted = true;

      // stop here if form is invalid
      if (this.setPasswordForm.invalid) {
        return;
      }

      this.isLoading = true;

      const rToken = await this.recaptchaV3Service.execute('setPassword').toPromise();
      const response = await this.guardianService.changePassword(
        this.token,
        this.emailAddress,
        this.setPasswordForm.value.password,rToken
      )
      if (response.isSuccess === true) {
        this.loginToSystem();
      } else {
        this.isLoading = false;
        this.alertService.error(response.status === 412 ? response.message : `Fail to Update Password`);
      }
    } catch (error) {
      this.isLoading = false;
      console.error("ChangePasswordComponent", "setPassword", error);
      this.alertService.error(`Fail to Update Password`);
    }
  };

  public async goToPage(menu) {
    switch (menu) {
      case "home":
        this.router.navigate(["/home"]);
        break;

      case "signIn":
        this.router.navigate(["/signIn"]);
        break;
      default:
        break;
    }
  }
}
