import { Component, OnInit } from '@angular/core';
import { WorkspaceService } from "../../../service/workspace/workspace.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginHandlerService } from "../../../service/auth/login-handler.service";
import { AlertService } from "../../../_alert";
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: 'app-user-role-map',
  templateUrl: './user-role-map.component.html',
  styleUrls: ['./user-role-map.component.scss']
})
export class UserRoleMapComponent implements OnInit {

 
  
  private wp = { workSpaceName: null, workspaceid: null };
  private ownerName = "";
  public key = 'memberid';
	public display = 'username';
  public format = {
		add: 'Add',
		remove: 'Remove',
		all: 'All',
		none: 'None',
		direction: 'left-to-right',
		draggable: true,
		locale: undefined
	};

  public roleName = null;
  public displayRoleName = null;
  public confirmed = [];
  public userList = [];
  public isLoading:boolean = false;
  
  constructor(public router: Router,private alertService: AlertService, private loginHandlerService: LoginHandlerService,private route:ActivatedRoute,private workspaceService : WorkspaceService) { }

  ngOnInit() {
    this.wp = this.loginHandlerService.GetWorkspace();
    this.route.params.subscribe(params => {
      this.roleName = params['role'];
      this.displayRoleName = this.roleName.replace(`${this.wp.workSpaceName}_`,"");
    });

   
    this.ownerName = this.loginHandlerService.GetUserName();
    this.loadUsers();
  }

  
  public loadUsers = async()=>{
    try {
     // this.confirmed = [];
      this.userList = [];
      this.isLoading = true;
      if(this.roleName && this.ownerName){
        const response = await this.workspaceService.getMembers(this.wp.workSpaceName,"*",this.ownerName,1,100000000);
        if(response){
          response.map((user)=>{
            if(user.role === this.roleName){
              this.confirmed.push(user);
              this.userList.push(user);
            }else {
              this.userList.push(user);
            }
            /* if(user.role === this.roleName){
              this.confirmed.push(user);
              this.userList.push(user);
            }else if(user.role === undefined || user.role === null || user.role === ""){
              this.userList.push(user);
            } */
          })          
        }
      }      
    } catch (error) {
      console.error("UserRoleMapComponent","loadUsers",error);
    }finally{
      this.isLoading = false;
    }
  }
  
  public addUsersToRole = async()=>{
    try {      
      this.isLoading = true;
      if(this.roleName && this.ownerName ){  
        const removedUser = this.userList.filter(val => !this.confirmed.includes(val) && val.role === this.roleName);
        const response = await this.workspaceService.addUserToRole(this.wp.workspaceid,this.roleName,this.confirmed,removedUser);
        if(response === true){
          this.alertService.success(`sellected users added to role ${this.roleName}`);     
        }else{
          this.alertService.warn(`Unable to add users to role ${this.roleName}`);     
        }
      }      
    } catch (error) {
      console.error("UserRoleMapComponent","loadUsers",error);
      this.alertService.error(`Unable to add users to role ${this.roleName}`);   
    }finally{
      this.loadUsers();
      this.isLoading = false;
    }
  }

}
