import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { async } from "@angular/core/testing";

@Injectable({
  providedIn: "root",
})
export class UserService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrlUser;
  }

  public async saveProfile(profileData) {
    const postData = {
      username: profileData.emailAddress,
      display_name: profileData.displayName,
      first_name: profileData.displayName,
      organization: "@@tetherfi_default___94",
      avatar_url: "1.png",
      third_party_id: profileData.third_party_id,
    };

    const jsonStr = JSON.stringify(postData);
    const reply = await this.httpClient
      .post(`${this.baseUrl}/user`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();

    return reply && reply["isSuccess"];
  }

  public async updateUserWorkspace(username, workspaceName) {
    const reply = await this.httpClient
      .put(
        `${this.baseUrl}/user/${username}/workspace/${workspaceName}`,
        {},
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .toPromise();

    return reply && reply["isSuccess"];
  }

  public checkUserAvailability = async (email: string) => {
    const reply = await this.httpClient
      .get(`${this.baseUrl}/users/${email}/availability`)
      .toPromise();
    if (reply && reply["isSuccess"]) {
      return reply["isSuccess"];
    } else {
      return false;
    }
  };

  /* async getUsersByPage(page, size) {
    return this.httpClient
      .get(`${this.baseUrl}/users/${page}/${size}`)
      .toPromise();
  }
  async getUsers(): Promise<object> {
    return new Promise(async (resolve, reject) => {
      try {
        let loadCustomers = true;
        let pageNo = 1;
        let customers = [];
        while (loadCustomers) {
          const response = await this.getUsersByPage(pageNo, 100);
          if (!response["isSuccess"] || response["result"] == null) {
            loadCustomers = false;
            resolve(customers);
          }
          pageNo++;
          customers.push(response["result"]);
        }
      } catch (e) {
        reject(e);
      }
    });
  } */

  async getUsersByPage(page, size) {
    return this.httpClient
      .get(
        `${this.baseUrl}/user-auth-service/api/v1/user/accounts?pageNo=${page}&pageSize=${size}`
      )
      .toPromise();
  }
  async getUsers(): Promise<object> {
    return new Promise(async (resolve, reject) => {
      try {
        let loadCustomers = true;
        let pageNo = 1;
        let customers = [];
        while (loadCustomers) {
          const response = await this.getUsersByPage(pageNo, 100);
          if (response["data"]["users"].length == 0) {
            loadCustomers = false;
            resolve(customers);
            return;
          }
          pageNo++;
          customers.push(...response["data"]["users"]);
        }
      } catch (e) {
        reject(e);
      }
    });
  }
}
