import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TemplatesService {
  baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrlTemplate;
  }

  public DeleteTemplate = async (uuid: string) => {
    try {
      const reply = await this.httpClient
        .delete(`${this.baseUrl}/TCMTemplates/Template/${uuid}`)
        .toPromise();

      return reply && reply["isSuccess"];
    } catch (error) {
      console.error("TemplatesService", "DeleteTemplate", error);
      return false;
    }
  };

  public GetTemplates = async (templateType: string) => {
    try {
      const reply = await this.httpClient
        .get(
          `${this.baseUrl}/TCMTemplates/Templates?TemplateType=${templateType}`
        )
        .toPromise();

      return reply && reply["isSuccess"] ? reply["result"] : null;
    } catch (error) {
      console.error("TemplatesService", "GetTemplateValues", error);
      return null;
    }
  };

  public GetTemplateValues = async (referenceValueType: string) => {
    try {
      const reply = await this.httpClient
        .get(
          `${this.baseUrl}/TCMTemplates/TemplateValues?ReferenceValueType=${referenceValueType}`
        )
        .toPromise();

      return reply && reply["isSuccess"] ? reply["result"] : null;
    } catch (error) {
      console.error("TemplatesService", "GetTemplateValues", error);
      return null;
    }
  };

  public GetTemplateValueMeta = async () => {
    try {
      const reply = await this.httpClient
        .get(`${this.baseUrl}/TCMTemplates/TemplateValueMeta`)
        .toPromise();

      return reply && reply["isSuccess"] ? reply["result"] : null;
    } catch (error) {
      console.error("TemplatesService", "GetTemplateValueMeta", error);
      return null;
    }
  };

  public SaveTemplate = async (postData) => {
    const jsonStr = JSON.stringify(postData);
    try {
      const reply = await this.httpClient
        .post(`${this.baseUrl}/TCMTemplates/Template`, jsonStr, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        })
        .toPromise();
      return reply;
    } catch (error) {
      console.error("TemplatesService", "SaveTemplate", error);
      return false;
    }
  };

  public AssignMultipleValuesToTemplate = async (
    templateId,
    valueIds: string[]
  ) => {
    const jsonStr = JSON.stringify({
      valueIds: valueIds,
    });
    try {
      const reply = await this.httpClient
        .post(
          `${this.baseUrl}/TCMTemplates/Template/${templateId}/AssignValues`,
          jsonStr,
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
            }),
          }
        )
        .toPromise();
      return reply && reply["isSuccess"];
    } catch (error) {
      console.error("TemplatesService", "SaveTemplate", error);
      return false;
    }
  };
}
