import { Component, OnInit } from '@angular/core';
import { WorkspaceService } from "../../../service/workspace/workspace.service";
import { LoginHandlerService } from "../../../service/auth/login-handler.service";
import { AlertService } from "../../../_alert";
import { ActivatedRoute ,Router } from "@angular/router";


@Component({
  selector: 'app-role-feature-map',
  templateUrl: './role-feature-map.component.html',
  styleUrls: ['./role-feature-map.component.scss']
})
export class RoleFeatureMapComponent implements OnInit {


  private wp = { workSpaceName: null, workspaceid: null };
  public key = 'feature_name';
	public display = 'feature_name';
  public format = {
		add: 'Add',
		remove: 'Remove',
		all: 'Select All',
		none: 'Reset',
		direction: 'left-to-right',
		draggable: true,
		locale: undefined
	};

  private featuresMapDB = [];
  public featuresMap = [];
  public systemFeatures = [];
  public isLoading = false;
  public roleName = null;
  public displayRoleName = null;

  constructor(private router: Router,private alertService: AlertService, private loginHandlerService: LoginHandlerService,private route:ActivatedRoute,private workspaceService : WorkspaceService) { }

  ngOnInit() {
    this.wp = this.loginHandlerService.GetWorkspace();
    this.route.params.subscribe(params => {
      this.roleName = params['role'];
      this.displayRoleName = this.roleName.replace(`${this.wp.workSpaceName}_`,"");
    });
    this.loadSystemFeatures();
  }

  public back = ()=>{
    this.router.navigate(['/console/configurations/roledetails'])
  }
  public loadSystemFeatures = async()=>{
    try {
      this.systemFeatures = [];
      this.featuresMap = [];
      this.featuresMapDB = [];
      this.isLoading = true;
      
      const response = await Promise.all([this.workspaceService.getSystemFeatures(),this.workspaceService.getUserRoleFeatures(this.roleName)]);
      if(response){
          response[0] && response[0].map((feature)=>{
            feature.role = this.roleName;
            this.systemFeatures.push(feature);
          });
          
          response[1] && response[1].map((feature)=>{
            this.featuresMap.push(feature);
          });  

          this.featuresMapDB = [...this.featuresMap];
      } 
    } catch (error) {
      console.error("UserRoleMapComponent","loadUsers",error);
    }finally{
      this.isLoading = false;
    }
  }


  public mapFeaturesToRole = async()=>{
    try {      
      this.isLoading = true;
      if(this.roleName  ){  
        const removedFeatures = this.featuresMapDB.filter(val => !this.featuresMap.includes(val));
        const addedFeatures = this.featuresMap.filter(val => !this.featuresMapDB.includes(val));
        const response = await this.workspaceService.mapFeaturesToRole(addedFeatures,removedFeatures);
        if(response === true){
          this.alertService.success(`sellected users added to role ${this.roleName}`);     
        }else{
          this.alertService.warn(`Unable to add users to role ${this.roleName}`);     
        }
      }      
    } catch (error) {
      console.error("UserRoleMapComponent","loadUsers",error);
      this.alertService.error(`Unable to add users to role ${this.roleName}`);   
    }finally{
      this.loadSystemFeatures();
      this.isLoading = false;
    }    
  }
  

}
