import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class GuardianService {
  baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrlGuardian;
  }

  public async changePassword(
    token: string,
    userName: string,
    password: string,rToken:string
  ) {
    const postData = {
      token: token,
      userName: userName,
      password: password,
    };
    const jsonStr = JSON.stringify(postData);
     return this.httpClient
      .put(`${this.baseUrl}/password?g-recaptcha-response=${rToken}`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise().then((data)=>{
        return { ...data}
      }).catch((error)=>{
         return { status: error.status, ...error.error}
      })

    //return reply && reply["isSuccess"];
  }

  public async changePasswordRequestValidation(
    token: string,
    userName: string,rToken:string
  ) {
    const postData = {
      token: token,
      userName: userName,
    };
    const jsonStr = JSON.stringify(postData);
    try {
      const reply = await this.httpClient
      .post(`${this.baseUrl}/password/token/validate?g-recaptcha-response=${rToken}`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
    return reply && reply["isSuccess"];
    } catch (error) {
      console.error('changePasswordRequestValidation',error);
      return false;
    }
    
  }

  public async passwordReset(email: string, token: string) {
    const postData = {
      userName: email, consoleName :'admin'
    };
    const jsonStr = JSON.stringify(postData);
    const reply = await this.httpClient
      .post(`${this.baseUrl}/password/reset?g-recaptcha-response=${token}`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "g-captcha": token,
        }),
      })
      .toPromise();
    return reply && reply["isSuccess"];
  }

  public async isEmailExisting(email: string, token: string) {
    const reply = await this.httpClient
      .get(`${this.baseUrl}/guardian/identity/${email}?g-recaptcha-response=${token}`, {
        headers: new HttpHeaders({
          "g-captcha": token,
        }),
      })
      .toPromise();

    return reply && reply["isSuccess"];
  }

  public async sendVerificationCode(emailAddress: string,token:string) {
    try {
      const postData = {
        userName: emailAddress,
      };
      const jsonStr = JSON.stringify(postData);
      const reply = await this.httpClient
        .post(`${this.baseUrl}/guardian/identity/register?g-recaptcha-response=${token}
        `, jsonStr, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        })
        .toPromise();

      return reply && reply["isSuccess"];
    } catch (error) {
      console.error("guardian", error);
     throw error;
    }
  }

  public async validateCode(validationCode: string, email: string,token:string) {
    const postData = {
      workspace: email,
      code: validationCode,
    };

    const jsonStr = JSON.stringify(postData);
    const reply = await this.httpClient
      .post(`${this.baseUrl}/guardian/identity/validate?g-recaptcha-response=${token}`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();

    return reply && reply["isSuccess"];
  }

  public async saveIdentity(email: string, password: string,token:string=null) {
    const postData = {
      username: email,
      password: password,
    };

    const jsonStr = JSON.stringify(postData);
    const reply = await this.httpClient
      .post(`${this.baseUrl}/guardian/identity?g-recaptcha-response=${token}`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();

    return reply && reply["isSuccess"];
  }

  public async login(username, password) {
    const jsonStr = JSON.stringify({ password: password, username: username ,console: "ADMIN_CONSOLE" });
    const reply = await this.httpClient
      .post(`${this.baseUrl}/login`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();

    return reply;
  }

  public async getToken(userName, refreshToken, organization) {
    const jsonStr = JSON.stringify({
      refreshToken: refreshToken,
      userName: userName,
    });
    const reply = await this.httpClient
      .post(`${this.baseUrl}/login/token/${organization}`, jsonStr, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();

    return reply && reply["isSuccess"] ? reply : { isSuccess: false };
  }
}
