import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { InsightsService } from "../../../../service/insights/insights.service";
import * as moment from "moment";
import { from } from "rxjs";
import { promise } from "protractor";
import { async } from "@angular/core/testing";
import * as Chart from "chart.js";
import { NgxSpinnerService } from "ngx-spinner";
import { LoginHandlerService } from "../../../../service/auth/login-handler.service";
import { AlertService } from "../../../../_alert";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  public concurrencyData: any;
  public sumData: any;
  public avgData: any;
  public countsData: any;
  public metric = [
    { metric: "CONV_CALLS", operations: ["TCOUNTS", "COUNTS"] },
    { metric: "CONV_CHATS", operations: ["TCOUNTS", "COUNTS"] },
    {
      metric: "INTERACTIONS",
      operations: ["TCOUNTS", "COUNTS", "SUM", "AVERAGE"]
    }
  ];
  private workspaceName = null;
  constructor(
    private insightsService: InsightsService,
    private spinner: NgxSpinnerService,
    private loginHandlerService: LoginHandlerService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.workspaceName = this.loginHandlerService.GetWorkspaceName();
    this.loadConcurrency();
  }

  private async getInsights(
    workspace: string,
    metric: string,
    type: string,
    fromDate: string,
    toDate: string,
    direction: string,
    channel: string
  ): Promise<string> {
    try {
      const queryParameters = `DIRECTION=${direction}&CHANNEL=${channel}`;
      const data = await this.insightsService.GetInsights(
        workspace,
        metric,
        type,
        fromDate,
        toDate,
        queryParameters
      );
      const t = {
        ...data,
        value: data[toDate] ? (data[toDate] < 0 ? 0 : data[toDate]) : 0, //Math.floor(Math.random() * 1000) data[toDate] ? data[toDate] : 0,
        metric: metric,
        channel: channel,
        direction: direction
      };
      console.log(t);
      return t;
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }

  private async loadConcurrency() {
    try {
      if (this.workspaceName === null) {
        this.alertService.error("Please Login To Workspace");
        return;
      }
      this.spinner.show();
      const direction = ["INBOUND", "OUTBOUND"];
      const channel = ["WEB_CHAT", "LINE", "FACEBOOK", "CALL"];

      let concurrencyList = [];
      let sumList = [];
      let avgList = [];
      let countList = [];

      this.metric.map(async m => {
        channel.map(async (c: string) => {
          direction.map(async (d: string) => {
            //const date = Date.now().toLocaleString("DD/MM/YYYY");
            const date = moment(new Date()).format("DD-MM-YYYY");

            m.operations.map(op => {
              let _insight = this.getInsights(
                this.workspaceName,
                m.metric,
                op,
                date,
                date,
                d,
                c
              );
              switch (op) {
                case "TCOUNTS":
                  concurrencyList.push(_insight);
                  break;
                case "SUM":
                  sumList.push(_insight);
                  break;
                case "AVERAGE":
                  avgList.push(_insight);
                  break;
                case "COUNTS":
                  countList.push(_insight);
                  break;
              }
            });
          });
        });
      });

      this.concurrencyData = this.formatDataToDisplay(
        await Promise.all(concurrencyList)
      );
      this.sumData = this.formatDataToDisplay(await Promise.all(sumList));
      this.avgData = this.formatDataToDisplay(await Promise.all(avgList));
      this.countsData = this.formatDataset(await Promise.all(countList));
      this.setupChart(this.countsData, this.metric, "count");
      //  this.setupChart(this.avgData, this.metric, "avg");

      this.spinner.hide();
    } catch (ex) {
      console.error(ex);
      this.alertService.error("Fail To Load Dashboard Data");
      this.spinner.hide();
    }
  }

  private formatDataToDisplay(dataset) {
    let chatData = {};

    try {
      if (dataset) {
        dataset.map((d, i) => {
          const data = chatData[`${d.metric}${d.channel}`];
          if (data) {
            if (d.direction === "INBOUND") {
              data.inbound = d.value;
            }
            if (d.direction === "OUTBOUND") {
              data.outbound = d.value;
            }
          } else {
            chatData[`${d.metric}${d.channel}`] = {
              inbound: d.direction === "INBOUND" ? d.value : 0,
              outbound: d.direction === "OUTBOUND" ? d.value : 0,
              channel: d.channel,
              metric: d.metric
            };
          }
        });
      }
      return chatData;
    } catch (ex) {
      console.error(ex);
      return chatData;
    }
  }

  private formatDataset(dataset) {
    let chatData = {};

    try {
      if (dataset) {
        dataset.map((d, i) => {
          let data = chatData[`${d.metric}`];
          if (data) {
            if (!data[d.channel]) {
              data.labels.push(d.channel);
              data[d.channel] = {
                inbound: [],
                outbound: []
              };
            }
            if (d.direction === "INBOUND") {
              data[d.channel].inbound = d.value;
            }
            if (d.direction === "OUTBOUND") {
              data[d.channel].outbound = d.value;
            }
          } else {
            chatData[`${d.metric}`] = {
              labels: [d.channel],
              [d.channel]: {
                inbound: 0,
                outbound: 0
              }
            };
            if (d.direction === "INBOUND") {
              chatData[`${d.metric}`][d.channel].inbound = d.value;
            }
            if (d.direction === "OUTBOUND") {
              chatData[`${d.metric}`][d.channel].outbound = d.value;
            }
          }
        });
      }
      return chatData;
    } catch (ex) {
      console.error(ex);
      return chatData;
    }
  }

  private random_rgba() {
    function randomNum(y, x) {
      //return Math.floor(Math.random() * val);
      return Math.floor(Math.random() * (y - x + 1) + x);
    }

    return `rgba(${randomNum(0, 255)},${randomNum(90, 140)},${randomNum(
      1,
      166
    )},1)`;
  }

  private renderChart(
    chatName: string,
    namePrefix: string,
    labels: [],
    inboundData: any,
    outboundData: any,
    backgroundColor: []
  ) {
    var barChartData = {
      labels: labels,
      datasets: [
        {
          label: "Inbound",
          backgroundColor: "pink",
          borderColor: "red",
          borderWidth: 1,
          data: inboundData
        },
        {
          label: "Outbound",
          backgroundColor: "lightblue",
          borderColor: "blue",
          borderWidth: 1,
          data: outboundData
        }
      ]
    };

    var chartOptions = {
      responsive: true,
      legend: {
        position: "top",
        display: false
      },
      title: {
        display: true,
        text: chatName.replace("_", " ")
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };

    const canvas: any = document.getElementById(`${chatName}`);
    const ctx = canvas.getContext("2d");
    ctx.textAlign = "center";
    ctx.textBaseline = "bottom";

    new Chart(ctx, {
      type: "bar",
      data: barChartData,
      options: chartOptions
    });
  }
  private setupChart(chartData: any, chartIds: any, namePrefix: string) {
    try {
      if (chartData) {
        for (const [key, value] of Object.entries(chartData)) {
          if (value["labels"]) {
            const inbound = [];
            const outbound = [];
            value["labels"].map(l => {
              inbound.push(value[l].inbound);
              outbound.push(value[l].outbound);
            });

            this.renderChart(
              key,
              namePrefix,
              value["labels"],
              inbound,
              outbound,
              null
            );
          }
        }
      }
    } catch (ex) {
      console.error("Fail to Setup Chart", ex);
    }
  }
  public sec_toString(seconds) {
    return moment("2015-01-01")
      .startOf("day")
      .seconds(seconds)
      .format("H:mm:ss");
  }
}
