import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { WorkspaceService } from "../../service/workspace/workspace.service";
import { LoginHandlerService } from "../../service/auth/login-handler.service";
import { EventEmitterService } from "../../service/eventEmitter/event-emitter.service";
import { UserService } from "../../service/user/user.service";
import { from } from "rxjs";
import { AlertService } from "../../_alert";
import { BasicConfig } from "../../config/constants";
import { PackageService } from "../../service/package/package.service";

@Component({
  selector: "app-workspace",
  templateUrl: "./workspace.component.html",
  styleUrls: ["./workspace.component.scss"],
})
export class WorkspaceComponent implements OnInit {
  public userTypes = [
    { type: "Agent", value: "agent" },
    { type: "Customer", value: "customer" },
    { type: "Admin", value: "admin" },
  ];
  private userType: string = "agent";
  routeUrl: string;
  isCreateNewWorkSpace: boolean = false;
  workSpaceWizardName: number = 1;
  public workspaceName: string;
  public workspaceForm: FormGroup;
  public workspaceInviteForm: FormGroup;
  public colleaguesOne: string;
  public colleaguesTwo: string;
  public isLoading: boolean = false;
  private savedWorkSpace: any;
  private userName: string = "";
  private basicConfig: any;
  private isWorkspaceAdded: boolean = false;
  public submitted = false;
  public availableToUseAsOfficialDomain: boolean = false;
  public autoAcceptJoinRequest: boolean = false;
  public useAsOfficialDomain: boolean = false;
  public domainName : string = "";
  public errorspattern : string = "Workspace name must contain alpha characters only";
  public min = 3;
  public max = 25;
  inviteSubmitted = false;

  constructor(
    location: Location,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private workspaceService: WorkspaceService,
    private loginHandlerService: LoginHandlerService,
    private eventEmitterService: EventEmitterService,
    private userService: UserService,
    private alertService: AlertService,
    private packageService:PackageService
  ) {
    this.basicConfig = BasicConfig;
    router.events.subscribe((val) => {
      if (location.path() != "") {
        this.routeUrl = location.path();
      } else {
        this.routeUrl = "rm";
      }
    });
  }

  ngOnInit() {
    try {
      window.addEventListener("scroll", this.scrollEvent, true);
      this.workspaceForm = this.formBuilder.group({
      workspaceName: [
          "",
          [
            Validators.required,
            Validators.pattern(this.basicConfig.alphabetsPattern),
            Validators.minLength(this.min),
            Validators.maxLength(this.max),
          ],
        ],
      });

      this.workspaceInviteForm = this.formBuilder.group({
        colleaguesOne: [
          "",
          [
            Validators.required,
            Validators.pattern(this.basicConfig.emailPattern),
            Validators.email,
            Validators.minLength(7),
            Validators.maxLength(50),
          ],
        ],
      });
      this.userName = this.loginHandlerService.GetUserName();
      var res = this.userName.split("@");   
      this.domainName = res[1];
    } catch (error) {
      console.error("ngOnInit", error);
    }
    
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.scrollEvent, true);
  }

  get f() {
    return this.workspaceForm.controls;
  }

  get fg() {
    return this.workspaceInviteForm.controls;
  }

  private async checkDomainAvailability(domainName){
    try {
     const status = await this.workspaceService.checkAvailability(domainName);
     return status[0] && status[0]["isSuccess"] === true;    
    } catch (error) {
      console.error("Fail To Create Workspace", error);
      this.alertService.error("Fail To Check Official Domain Status");
    }
  }
  loadWorkspaces() {
    try {
      this.eventEmitterService.onNewWorkspaceAdded(this.savedWorkSpace);
    } catch (error) {
      console.error("workspace", "onNewWorkspaceAdded", error);
    }
  }

  scrollEvent = (event: any): void => {
    const number = event.srcElement.scrollTop;
    console.log(number);
  };

  private async buyDefaultBasePackage(workspaceName){
    try {
       return await this.packageService.buyDefaultBasePackage(workspaceName);
    } catch (error) {
      console.error("SignupWorkspaceComponent","Fail To Add Default Package to Workspace", error);
      this.alertService.error("Fail To Add Default Package to Workspace");
      return false;
    }
  }

  private async update_official_domain(workspaceId, domain,autoAcceptRequest){
    try {
      if(this.useAsOfficialDomain){     
        const response = await this.workspaceService.update_official_domain(
          workspaceId, domain,autoAcceptRequest
        );
        if(!response){
          this.alertService.error("Fail To Update Official Domain Setting");
        }
      }
      
    } catch (error) {
      this.alertService.error("Fail To Update Official Domain Setting");
      console.error(error);
    }
  }

  public useAsOfficialDomainChange = () => {
    try {
      this.useAsOfficialDomain = !this.useAsOfficialDomain;     
      if(this.useAsOfficialDomain === true){       
        this.errorspattern = "Workspace name must be domain name"; 
        this.min = 3;
        this.max = 25;
        this.workspaceForm.get("workspaceName").setValidators([ Validators.required, Validators.pattern(this.basicConfig.domainPattern), Validators.minLength(this.min), Validators.maxLength(this.max)]);          
        this.workspaceForm.get("workspaceName").setValue(this.domainName);
      }else{
        this.min = 7;
        this.max = 50;
        this.workspaceForm.get("workspaceName").setValidators([ Validators.required, Validators.pattern(this.basicConfig.alphabetsPattern), Validators.minLength(7), Validators.maxLength(50)]);          
        this.errorspattern = "Workspace name must contain alpha characters only";
      }
      this.workspaceForm.get("workspaceName").updateValueAndValidity();
    } catch (error) {
      console.error(error);
    }    
  };
  
  public onKeydown(event) {
    if (event.key === "Enter") {
      if (this.workspaceName) this.saveWorkspace();
    }
  }

  public async saveWorkspace() {
    try {
      this.submitted = true;
      // stop here if form is invalid
      if (this.workspaceForm.invalid) {
        return;
      }

      this.isLoading = true;

      
      let reply = await this.workspaceService.checkWorkspaceNameAvailability(
        this.workspaceForm.value.workspaceName
      );
      if (reply) {
        if(this.useAsOfficialDomain){
          const isAvailable = await this.checkDomainAvailability(this.workspaceForm.value.workspaceName);
          if(isAvailable){
            console.error("SignupWorkspaceComponent","checkDomainAvailability","workspace name not available to use as official domain");
            this.alertService.error("workspace name not available to use as official domain");
            this.isLoading = false;
            return;
          }
        }

        reply = await this.workspaceService.registerWorkspace(
          this.workspaceForm.value.workspaceName,
          this.userName
        );

        if (reply && reply["isSuccess"]) {
          /* await this.workspaceService.updateUserWorkspace(this.userName,this.workspaceForm.value.workspaceName); // update or create new profile to owner
          await this.buyDefaultBasePackage(this.workspaceForm.value.workspaceName); */

          await Promise.all([this.workspaceService.updateUserWorkspace(this.userName,this.workspaceForm.value.workspaceName),this.buyDefaultBasePackage(this.workspaceForm.value.workspaceName),this.update_official_domain(reply["result"]["workspaceid"],this.workspaceForm.value.workspaceName,this.autoAcceptJoinRequest)]);
          this.isWorkspaceAdded = true;
          this.alertService.success("Workspace Registered Successfully");
          this.isLoading = false;
          this.savedWorkSpace = reply["result"];
          this.goToPage("nextWizard");

          return;
        }
        this.isLoading = false;
        this.alertService.error("Fail To Register Workspace.");
        return;
      }
      this.isLoading = false;
      this.alertService.error("Workspace Name Already Used By Another User");
    } catch (ex) {
      this.isLoading = false;
      console.error("Fail To Create Workspace", ex);
      this.alertService.error("Fail To Create Workspace");
    }
  }
  public selectionChange = (value) => {
    this.userType = value;
  };
  public async SendInvitations() {
    try {
      this.inviteSubmitted = true;
      // stop here if form is invalid
      if (this.workspaceInviteForm.invalid) {
        return;
      }

      this.isLoading = true;

      const reply = await this.workspaceService.sendInvitations(
        [
          this.workspaceInviteForm.value.colleaguesOne,
          this.workspaceInviteForm.value.colleaguesTwo,
        ],
        this.userName,
        this.savedWorkSpace.workspaceid,
        this.savedWorkSpace.workSpaceName,
        null,
        this.userType
      );
      if (reply) {
        this.loadWorkspaces();
        this.isLoading = false;
        this.goToPage("colleagues");
        return;
      }
      this.isLoading = false;
      this.alertService.error("Fail To Send Invitations or Already invited");
    } catch (ex) {
      this.isLoading = false;
      this.alertService.error("Fail To Send Invitations or Already invited");
      console.error(ex);
    }
  }

  public async goToPage(_page) {
    switch (_page) {
      case "createNewWorkspace":
        /* if(this.isSignup){
          this.goToPage("/signIn");
          return;
        } */
        this.isCreateNewWorkSpace = !this.isCreateNewWorkSpace;
        this.workSpaceWizardName = 1;
        if (this.isWorkspaceAdded) this.loadWorkspaces();
        break;
      case "colleagues":
        this.isCreateNewWorkSpace = !this.isCreateNewWorkSpace;
        this.router.navigate(["/console/colleagues/invite"]);
        break;
      case "nextWizard":
        //1 workspace name
        //2 invite colleagues
        if (this.workSpaceWizardName == 1) {
          this.workSpaceWizardName = 2;
          return;
        }
        if (this.workSpaceWizardName == 2) {
          this.workSpaceWizardName = 1;
          return;
        }
        break;
      default:
        break;
    }
  }
}
