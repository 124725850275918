import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ClienteleService } from "../../service/clientele/clientele.service";
import { UserService } from "../../service/user/user.service";
import { RelationshipService } from "../../service/relationship/relationship.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { AlertService } from "../../_alert";
import { WorkspaceService } from "../../service/workspace/workspace.service";
import { LoginHandlerService } from "../../service/auth/login-handler.service";
import { UserAuthService } from "../../service/userAuth/user-auth.service";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-rmhandler",
  templateUrl: "./rmhandler.component.html",
  styleUrls: ["./rmhandler.component.scss"],
})
export class RmhandlerComponent implements OnInit {
  private customers: Array<any> = [];
  public users: Array<any> = [];
  public addedCustomers: Array<any> = [];
  public availableCustomers: Array<any> = [];
  public customerTypes: object = [];
  public selectedCustomer: object = {};
  public selectedUser = "";
  public selectedCustomerType = "";
  public isOpenCustomerMap = false;
  public isLoading = true;
  public selectedColleague: any = {};
  error_occor = false;
  error_msg = "";
  form: FormGroup;
  dropdownSettings: any = {};
  public profileAvatarKey = {
    0: "https://source.unsplash.com/CjI5Zg-qzEo/250x250",
    1: "https://source.unsplash.com/RukI4qZGlQs/250x250",
    2: "https://source.unsplash.com/8ItNia0nMVA/250x250",
    3: "https://source.unsplash.com/TLD6iCOlyb0/250x250",
    4: "https://source.unsplash.com/xDyqR14KyAs/250x250",
    5: "https://source.unsplash.com/HKuhUKNWFtY/250x250",
    6: "https://source.unsplash.com/jmURdhtm7Ng/250x250",
    7: "https://source.unsplash.com/BGz8vO3pK8k/250x250",
    8: "https://source.unsplash.com/d-MfHM-jHwc/250x250",
    9: "https://source.unsplash.com/QXevDflbl8A/250x250",
    10: "https://source.unsplash.com/eSjmZW97cH8/250x250",
    11: "https://source.unsplash.com/n4KewLKFOZw/250x250",
    12: "https://source.unsplash.com/8wbxjJBrl3k/250x250",
    13: "https://source.unsplash.com/7ip-IOC2yvA/250x250",
    14: "https://source.unsplash.com/jTSf1xnsoCs/250x250",
    15: "https://source.unsplash.com/9IL_stSpvOU/250x250",
    16: "https://source.unsplash.com/CjI5Zg-qzEo/250x250",
    17: "https://source.unsplash.com/xDyqR14KyAs/250x250",
  };

  constructor(
    private router: Router,
    private _clienteleService: ClienteleService,
    private _userService: UserService,
    private _relationshipService: RelationshipService,
    private loginHandlerService: LoginHandlerService,
    private formBuilder: FormBuilder,
    private workspaceService: WorkspaceService,
    private alertService: AlertService,
    private userAuthService: UserAuthService
  ) {}

  ngOnInit() {
    //this.loadCustomers();
    this.loadMembers();
    //this.loadCustomerType();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "userName",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 10,
      allowSearchFilter: true,
    };

    const workspaceName = this.loginHandlerService.GetWorkspaceName();
    if (workspaceName === null) {
      this.alertService.error("Please Login To Workspace");
      return;
    }
  }

  onItemSelect(item: any) {
    console.log("onItemSelect", item);
  }

  onSelectAll(items: any) {
    console.log("onSelectAll", items);
  }

  toogleShowFilter() {
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      allowSearchFilter: true,
    });
  }

  /* public async loadCustomers() {
    let response = await this._clienteleService.getCustomers();
    if (response) {
      this.customers = response[0];
      this.form.controls.selectedCustomer.patchValue(
        [this.customers[0]]
      );

      return;
    }
  } */

  private getMembers = (workspaceName: string, owner: string) => {
    return new Promise((resolve) => {
      try {
        let isDoneLoading: boolean = true;
        let userIds: string[] = [];
        ["admin", "agent", "customer"].map(async (memberType) => {
          try {
            let pageNumber: number = 1;
            while (isDoneLoading) {
              const members = await this.workspaceService.getMembers(
                workspaceName,
                memberType,
                owner,
                pageNumber
              );
              pageNumber++;
              isDoneLoading = members && members.length > 0;
              if (members) {
                return members.map((item: any) => {
                  userIds.push(item.userid);
                });
              }
            }
          } catch (error) {
            console.error(
              "rmhandle",
              "getMembers",
              `memberType : ${memberType}`,
              error
            );
          }
          resolve(userIds);
        });       
      } catch (error) {
        console.error("rmhandle", "getMembers", error);
        resolve(null);
      }
    });
  };

  private getProfile = async (workspaceName: string, userIds: string[]) => {
    try {
      const profiles = await this.userAuthService.getProfiles(
        userIds,
        workspaceName
      );
      console.log(
        "rmhandle",
        "loadMembers",
        "getProfiles",
        JSON.stringify(profiles)
      );
      if (profiles) {
        profiles.map((i) => {
          switch (i.userType) {
            case "Admin":
            case "Agent":
              this.users.push(i);
              break;
            case "Customer":
              this.customers.push(i);
              break;
          }
        });
      }
    } catch (error) {
      console.error("rmhandle", "getProfile", error);
    }
  };

  //DataTypes.ENUM("admin", "agent", "customer")
  private loadMembers = async () => {
    try {
      this.isLoading = true;
      let isDoneLoading: boolean = true;
      let pageNumber: number = 1;
      const workspaceName = this.loginHandlerService.GetWorkspaceName();
      const owner = this.loginHandlerService.GetUserName();

      const userIds:any = await this.getMembers(workspaceName, owner);
      pageNumber++;
      isDoneLoading = userIds && userIds.length > 0;
      if (userIds) {
        await this.getProfile(workspaceName, userIds);
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error("rmhandle", "loadMembers", error);
    }
  };

  /* public async loadUsers() {
    try {
      this.isLoading = true;
      let response = await this._userService.getUsers();
      if (response) {
        if (Array.isArray(response)) {
          response.map((i) => {
            switch (i.userType) {
              case "Admin":
              case "Agent":
                this.users.push(i);
                break;
              case "Customer":
                this.customers.push(i);
                break;
            }
          });
        }

        this.isLoading = false;
        return;
      }
    } catch (ex) {
      this.isLoading = false;
      console.error("loadUsers", ex);
    }
  } */

  public async getCustomersByManager(userId) {
    let response = await this._relationshipService.getCustomersByManager(
      userId
    );
    if (response["isSuccess"] && Array.isArray(response["result"])) {
      response["result"].map(
        function (i) {
          const u = this.customers.find((x) => x.userId === i.customerId);
          if (u) {
            this.addedCustomers.push(u);
            this.availableCustomers.splice(
              this.availableCustomers.indexOf(u),
              1
            );
          }
        },
        {
          customers: this.customers,
          addedCustomers: this.addedCustomers,
          availableCustomers: this.availableCustomers,
        }
      );
    }
  }

  public removeCustomer = async (customer) => {
    try {
      const reply = await this._relationshipService.removeRelationship(
        this.selectedColleague.userId,
        customer.userId
      );
      if (reply) {
        this.alertService.success(`Successfully Delete Relationship`);
        this.availableCustomers.push(customer);
        this.addedCustomers.splice(this.addedCustomers.indexOf(customer), 1);
        return;
      }
      this.alertService.error("Fail to Delete Customer Relationship");
    } catch (ex) {
      console.error("addCustomer", ex);
      this.alertService.error("Fail to Delete Customer Relationship");
    }
  };

  public addCustomer = async (customer) => {
    try {
      const reply = await this._relationshipService.saveRelationship(
        this.selectedColleague.userId,
        [customer.userId],
        null
      );
      if (reply) {
        this.alertService.success(`Successfully Save Relationship`);
        this.addedCustomers.push(customer);
        this.availableCustomers.splice(
          this.availableCustomers.indexOf(customer),
          1
        );
        return;
      }
      this.alertService.error("Fail to Save Customer Relationship");
    } catch (ex) {
      console.error("addCustomer", ex);
      this.alertService.error("Fail to Save Customer Relationship");
    }
  };

  public async goToCustomerMap(user) {
    this.availableCustomers = [...this.customers];
    this.addedCustomers = [];
    this.getCustomersByManager(user.userId);
    this.isOpenCustomerMap = true;
    this.selectedColleague = user;
  }

  public async closeCustomerMap() {
    this.isOpenCustomerMap = false;
    this.selectedColleague = { userId: null };
  }
}
