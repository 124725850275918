import { Component, OnInit } from "@angular/core";
import { InteractionService } from "../../../../service/interaction/interaction.service";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { callbackify } from "util";
import { LoginHandlerService } from "../../../../service/auth/login-handler.service";
import { AlertService } from "../../../../_alert";
import { NgxSpinnerService } from "ngx-spinner";
import { from } from "rxjs";
import * as moment from "moment";

@Component({
  selector: "app-interaction",
  templateUrl: "./interaction.component.html",
  styleUrls: ["./interaction.component.scss"]
})
export class InteractionComponent implements OnInit {
  public rowData;
  public startDateTime;
  public endDateTime;
  public columnDefs = [
    { field: "session_id", headerName: "session id" },
    { field: "from_id", headerName: "from id" },
    { field: "from_name", headerName: "from name", sortable: true },
    { field: "to_id", headerName: "to id" },
    { field: "to_name", headerName: "to name", sortable: true },
    { field: "received_id", headerName: "received id" },
    { field: "received_name", headerName: "received name", sortable: true },
    { field: "sid", headerName: "sid" },
    { field: "started_at", headerName: "started at" },
    { field: "ended_at", headerName: "ended at" },
    { field: "channel", headerName: "channel", sortable: true },
    { field: "workspace", headerName: "workspace" },
    { field: "procesing_status", headerName: "processing status" },
    { field: "handling_time", headerName: "handling time" },
    { field: "message_count", headerName: "message count" },
    { field: "createdAt", headerName: "created at" },
    { field: "updatedAt", headerName: "updated at" }
  ];
  public date = null;
  public model;
  public bsRangeValue: Date[];
  public maxDate: Date;
  public channels = [
    { value: "All", key: "ALL" },
    { value: "Line", key: "LINE" },
    { value: "Facebook", key: "FACEBOOK" },
    { value: "Chat", key: "WEB_CHAT" },
    { value: "Call", key: "CALL" }
  ];
  public types = [
    { value: "All", key: -9 },
    { value: "Call", key: 1 },
    { value: "Chat", key: 2 },
    { value: "Media", key: 3 }
  ];
  public selectedChannel: string = "ALL";
  public selectedType: number = -9;
  private workspaceName;
  constructor(
    private interactionService: InteractionService,
    private calendar: NgbCalendar,
    private toastr: ToastrService,
    private loginHandlerService: LoginHandlerService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService
  ) {}

  async ngOnInit() {
    this.workspaceName = this.loginHandlerService.GetWorkspaceName();
    this.startDateTime = new Date(new Date().setUTCHours(0, 0, 0, 0));
    this.endDateTime = new Date(new Date().setUTCHours(24, 0, 0, 0));
    this.bsRangeValue = [this.startDateTime, this.endDateTime];
    this.maxDate = new Date(new Date().setUTCHours(24, 0, 0, 0));
  }

  public sec_toString(seconds) {
    return moment("2015-01-01")
      .startOf("day")
      .seconds(seconds)
      .format("H:mm:ss");
  }
  public async loadData() {
    try {
      if (this.workspaceName === null) {
        this.alertService.error("Please Login To Workspace");
        return;
      }
      this.spinner.show();
      this.rowData = [];
      this.rowData = await this.interactionService.getInteractions(
        this.workspaceName,
        this.selectedChannel,
        this.selectedType,
        1,
        this.startDateTime.toISOString(),
        this.endDateTime.toISOString()
      );
      this.spinner.hide();
      if (this.rowData.length == 0) {
        this.alertService.info("Data Not Found");
      }
    } catch (ex) {
      this.alertService.error("Data Not Found/Invalid Request");
      console.log(ex);
      this.spinner.hide();
    }
  }

  public selectionTypeChange(value) {
    this.selectedType = value;
  }
  public selectionChange(value) {
    this.selectedChannel = value;
  }

  onValueChange(bsRangeValue: string): void {
    console.log("filterValue", bsRangeValue);
    /* this.startDateTime = new Date(bsRangeValue[0]);
    this.endDateTime = new Date(bsRangeValue[1]);
 */
    /* this.startDateTime = new Date(bsRangeValue[0]).setHours(0, 0, 0, 0);
    this.endDateTime = new Date(bsRangeValue[1]).setHours(24, 0, 0, 0);
 */

    this.startDateTime = new Date(
      new Date(bsRangeValue[0]).setUTCHours(0, 0, 0, 0)
    );
    this.endDateTime = new Date(
      new Date(bsRangeValue[1]).setUTCHours(24, 0, 0, 0)
    );
    this.bsRangeValue = [this.startDateTime, this.endDateTime];
  }

  onChange(result: Date): void {
    console.log("onChange: ", result);
  }
}
