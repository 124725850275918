// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  baseUrlGuardian: window["env"]["baseUrlGuardian"] || "https://guardian.qa2.tetherfi.cloud",
  baseUrlUser: window["env"]["baseUrlUser"] || "https://userservice.qa2.tetherfi.cloud",
  baseUrlPresenceService: window["env"]["baseUrlPresenceService"] || "https://presenceapi.qa2.tetherfi.cloud",
  baseUrlWorkspace:  window["env"]["baseUrlWorkspace"] || "https://workspace.qa2.tetherfi.cloud",
  baseUrlClientele: window["env"]["baseUrlClientele"] || "https://tetherfi.cloud:13011",
  baseUrlRelationship: window["env"]["baseUrlRelationship"] || "https://relationship.qa2.tetherfi.cloud",
  baseUrlInteraction:  window["env"]["baseUrlInteraction"] || "https://interactions.qa2.tetherfi.cloud",
  baseUrlInsights: window["env"]["baseUrlInsights"] || "https://insights.qa2.tetherfi.cloud",
  baseUrlTemplate: window["env"]["baseUrlTemplate"] || "https://templateservice.qa2.tetherfi.cloud",
  baseUserAccount: window["env"]["baseUserAccount"] || "https://userservice.qa2.tetherfi.cloud",
  baseUrlPackage: window["env"]["baseUrlPackage"] || "https://billing.qa2.tetherfi.cloud",
  TCMConsole: window["env"]["TCMConsole"] || "https://agent.qa2.tetherfi.cloud",
  baseUrlReportsApi: window["env"]["baseUrlReportsApi"] || "https://tcmreportsapi.qa.tetherfi.cloud",
  baseUrlCallRecorderApi: window["env"]["baseUrlCallRecorderApi"] || "https://tcmforwardproxy.qa.tetherfi.cloud",
  overwriteCallRecorderUrl: window["env"]["overwriteCallRecorderUrl"] || false,
  
  appVersion: require('../../package.json').version 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
