import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { WorkspaceService } from "../../../service/workspace/workspace.service";
import { AlertService } from "../../../_alert";
import { LoginHandlerService } from "../../../service/auth/login-handler.service";
import { ActivatedRoute ,Router } from "@angular/router";

@Component({
  selector: "app-restriction-map",
  templateUrl: "./restriction-map.component.html",
  styleUrls: ["./restriction-map.component.scss"],
})
export class RestrictionMapComponent implements OnInit {

  public restrictionAdd: FormGroup;
  public submitted: boolean = false;
  public isLoading: boolean = false;
  public featureName:string = "";
  public featureID:string="";
  public role:string="";

  private wp = { workSpaceName: null, workspaceid: null };
  public key = 'name';
	public display = 'name';
  public format = {
		add: 'Add',
		remove: 'Remove',
		all: 'All',
		none: 'None',
		direction: 'left-to-right',
		draggable: true,
		locale: undefined
	};

  public confirmed = [];
  public restrictions = [];
  constructor(
    private router: Router,
    private route:ActivatedRoute,
    private loginHandlerService: LoginHandlerService,
    private alertService: AlertService,
    private workspaceService: WorkspaceService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.featureName = params['feature'];
      this.featureID = params['featureID'];
      this.role = params['role'];
    });
    this.wp = this.loginHandlerService.GetWorkspace();
    this.restrictionAdd = this.formBuilder.group(
      {
        name: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(20),
          ],
        ],
        restrictions: ["", [Validators.required, Validators.minLength(7)]],
      },
      {
        validator: this.MustMatch("restrictions"),
      }
    );
    this.loadRestrictions();
    
  }

  MustMatch(controlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];

      if (control.errors && !control.errors.mustMatch) {
        return;
      }
      try {
        JSON.parse(control.value);
        control.setErrors(null);
      } catch (e) {
        control.setErrors({ mustMatch: true });
      }
    };
  }
  get f() {
    return this.restrictionAdd.controls;
  }
  onReset() {
    this.restrictionAdd.reset();
    this.submitted = false;
  }

  public loadRestrictions = async()=>{
    try {
     // this.confirmed = [];
      this.restrictions = [];
      this.isLoading = true;
      if(this.featureID){
        const results = await Promise.all([this.workspaceService.get_restrictions(),await this.workspaceService.loadFeatureRestrictions(this.featureID)]);
        if(results){
          this.restrictions = results[0] ? results[0] : [];
          this.confirmed = results[1] ? results[1] : [];
          this.confirmed && this.confirmed.map((r)=>{
            try {
              const fr = this.restrictions.find((item)=> item.name === r.name );
              if(fr){
                delete this.restrictions[fr];
              }
            } catch (error) {
              
            }
          })
        }
      }      
    } catch (error) {
      console.error("UserRoleMapComponent","loadUsers",error);
    }finally{
      this.isLoading = false;
    }
  }

  public add_feature_restriction = async () => {
    try {
      this.submitted = true;
      if (this.restrictionAdd.invalid) {
        return;
      }
      this.isLoading = true;
      const response = await this.workspaceService.add_feature_restriction(
        this.wp.workspaceid,
        this.restrictionAdd.value.name,
        this.restrictionAdd.value.restrictions
      );
      if (response === true) {
        this.alertService.success(`Restriction successfully saved`);
        this.onReset();
      } else {
        this.alertService.warn(`Fail to save restriction`);
      }
    } catch (error) {
      console.error("add_restriction", error);
      this.alertService.error(`Fail to save restriction`);
    } finally {
      this.isLoading = false;
    }
  };

  /* public add_restriction = async () => {
    try {
      this.submitted = true;
      if (this.restrictionAdd.invalid) {
        return;
      }
      this.isLoading = true;

      const response = await this.workspaceService.add_restriction(
        this.wp.workspaceid,
        this.restrictionAdd.value.name,
        this.restrictionAdd.value.restrictions
      );
      if (response === true) {
        this.alertService.success(`Restriction successfully saved`);
        this.onReset();
      } else {
        this.alertService.warn(`Fail to save restriction`);
      }
    } catch (error) {
      console.error("add_restriction", error);
      this.alertService.error(`Fail to save restriction`);
    } finally {
      this.isLoading = false;
    }
  }; */

  
  public back =()=>{
    this.router.navigate(["/console/configurations/roledetails"]);
  }
}
