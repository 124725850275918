import { Component, OnInit, Input } from "@angular/core";
import { WorkspaceService } from "../../../service/workspace/workspace.service";
import { LoginHandlerService } from "../../../service/auth/login-handler.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { from } from "rxjs";
import { AlertService } from "../../../_alert";
import { BasicConfig } from "../../../config/constants";
import { Colleague } from "./colleague/Colleague";
import { ActivatedRoute, Router } from "@angular/router";
import { async } from '@angular/core/testing';

@Component({
  selector: "app-colleagues",
  templateUrl: "./colleagues.component.html",
  styleUrls: ["./colleagues.component.scss"],
})
export class ColleaguesComponent implements OnInit {
  @Input("app-colleague") data: any;

  public userTypes = [
    { type: "Admin", value: "admin" },
    { type: "Agent", value: "agent" },
    { type: "Customer", value: "customer" },   
    { type: "Requester", value: "requester" },    
  ];
  public userType: string = "agent";
  public userRole: string = "agent";
  public members: Colleague[];
  public colleaguesOne: string;
  public workspaceInviteForm: FormGroup;
  public wp = { workSpaceName: null, workspaceid: null };
  private invitationsList: any = {};
  private basicConfig: any;

  public isLoading: boolean = false;
  public submitted = false;

  
  constructor(
    private workspaceService: WorkspaceService,
    private formBuilder: FormBuilder,
    private loginHandlerService: LoginHandlerService,
    private alertService: AlertService,  private router: Router,
  ) {
    this.basicConfig = BasicConfig;
  }

  async ngOnInit() {
    this.wp = this.loginHandlerService.GetWorkspace();
    this.workspaceInviteForm = this.formBuilder.group({
      colleaguesOne: [
        "",
        [
          Validators.required,
          Validators.pattern(this.basicConfig.emailPattern),
          Validators.minLength(7),
          Validators.maxLength(50),
        ],
      ],
    });

    const workspaceName = this.loginHandlerService.GetWorkspaceName();
    if (workspaceName === null) {
      this.alertService.error("Please Login To Workspace");
      return;
    }

    await this.loadMembers(1);
  }

  get fg() {
    return this.workspaceInviteForm.controls;
  }
  

  private async loadMembers(pageNumber: number) {
    const ownerName = this.loginHandlerService.GetUserName();
    const tempMembers = await this.workspaceService.getMembers(
      this.loginHandlerService.GetWorkspaceName(),
      this.userRole,
      ownerName,
      pageNumber
    );

    this.members = null;
    if (tempMembers)
      this.members = tempMembers.map((i) => {
        i.workSpaceName = this.wp.workSpaceName;
        i.workspaceid = this.wp.workspaceid;
        i.ownerName = ownerName;
        return new Colleague(
          i,
          this.workspaceService,
          this.alertService,
          this.loadMembers.bind(this)
        );
      });
  }
  public selectionRole = async(value) => {
    this.members = null;
    this.userRole = value;
    await this.loadMembers(1);
  };
  public selectionChange = (value) => {
    this.userType = value;
  };
  public addMember = () => {
    this.submitted = true;
    // stop here if form is invalid
    if (this.workspaceInviteForm.invalid) {
      return;
    }

    this.invitationsList[
      this.workspaceInviteForm.value.colleaguesOne
    ] = this.workspaceInviteForm.value.colleaguesOne;
  };

  public removeMember = (id) => {
    delete this.invitationsList[id];
  };

  public async addProfile() {
    this.router.navigate(["/console/colleagues/profile"]);
  }
  public async sendInvitations() {
    try {
      if (this.wp === null || this.wp.workSpaceName === null) {
        this.alertService.error("Please Login To Workspace");
        return;
      }

      this.submitted = true;
      // stop here if form is invalid
      if (this.workspaceInviteForm.invalid) {
        return;
      }

      this.isLoading = true;

      const reply = await this.workspaceService.sendInvitations(
        [this.workspaceInviteForm.value.colleaguesOne],
        this.loginHandlerService.GetUserName(),
        this.wp.workspaceid,
        this.wp.workSpaceName,
        null,
        this.userType
      );
      if (reply) {
        await this.loadMembers(1);
        this.isLoading = false;
        this.alertService.success(`Invitation Successfully Send`);
        this.invitationsList = [];
        this.submitted = false;
        this.workspaceInviteForm.reset();
        return;
      }
      this.isLoading = false;
      this.alertService.error(
        `Fail To Send Invitations or Already invited.[${this.workspaceInviteForm.value.colleaguesOne}]`
      );
    } catch (ex) {
      this.isLoading = false;
      this.alertService.error("Fail To Send Invitations or Already invited");
      console.error(ex);
    }
  }
}
