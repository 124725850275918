import { Component, OnInit,Input } from '@angular/core';


@Component({
  selector: 'app-colleague',
  templateUrl: './colleague.component.html',
  styleUrls: ['./colleague.component.scss']
})
export class ColleagueComponent implements OnInit {

  ngOnInit() {
  }

  @Input('app-colleague') data: any;

}
