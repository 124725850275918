import { Component, ViewChild, ElementRef, OnInit } from "@angular/core";
import { TemplatesService } from "../../../../service/templates/templates.service";
import { LoginHandlerService } from "../../../../service/auth/login-handler.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { from } from "rxjs";
import { AlertService } from "../../../../_alert";
import { BasicConfig } from "../../../../config/constants";
import { ActivatedRoute, Router } from "@angular/router";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-message-templates",
  templateUrl: "./message-templates.component.html",
  styleUrls: ["./message-templates.component.scss"],
})
export class MessageTemplatesComponent implements OnInit {
  public isLoading: boolean = false;
  public selectedTemplateType = "TEMPLATE";
  public templateTypes = [
    { type: "Template", value: "TEMPLATE" },
    { type: "Canned Admin", value: "CANNED_ADMIN" },
  ];
  public templates = [];

  constructor(
    private templatesService: TemplatesService,
    private formBuilder: FormBuilder,
    private loginHandlerService: LoginHandlerService,
    private alertService: AlertService,
    private router: Router 
  ) {}

  ngOnInit() {
    this.GetTemplates();
  }

  public templateTypesChange = (value) => {
    this.selectedTemplateType = value;
    this.GetTemplates();
  };

  public DeriveActualMessage = (templates) => {
    try {
      if (templates) {
        templates = templates.map((template) => {
          if (template && template.TemplateMessage) {
            /* const matchedText = new RegExp(/{{.+?(?=)}}/, "gm").exec(template.TemplateMessage);
            if (matchedText != null) {
              template.TemplateMessage = template.TemplateMessage.replaceAll(matchedText, "{{Customer Name}}");
            } */
            let nameMap = {};
            if (template.templateValues) {
              Object.values(template.templateValues).map((item: any) => {
                nameMap[
                  item.Uuid
                ] = `{{${item.ReferenceValueType}.${item.ReferenceId}}}`;
              });
            }
            var s = template.TemplateMessage;
            var m;
            do {
              m = new RegExp(/{{.+?(?=)}}/, "gm").exec(s);
              if (m) {
                const id = m[0].replace("{{", "").replace("}}", "");
                s = s.replace(m[0], "-----");
                if (nameMap[id]) {
                  template.TemplateMessage = template.TemplateMessage.replace(
                    m[0],
                    nameMap[id]
                  );
                }
              }
            } while (m);
          }
          return template;
        });
      }
    } catch (error) {
      console.error("MessageTemplatesComponent", "DeriveActualMessage", error);
    }
  };
  public GetTemplates = async () => {
    try {
      this.isLoading = true;
      this.templates = [];
      this.templates = await this.templatesService.GetTemplates(
        this.selectedTemplateType
      );
      this.DeriveActualMessage(this.templates);
    } catch (error) {
      console.error("MessageTemplatesComponent", "GetTemplates", error);
    } finally {
      this.isLoading = false;
    }
  };

  public CreateTemplate = () => {
    try {
      this.router.navigate(["/console/settings/messagetemplate"]);
    } catch (error) {
      console.error("MessageTemplatesComponent", "CreateTemplate", error);
    }
  };
  public DeleteTemplate = async (uuid,name) => {
    try {
      var r = confirm(`do you want to delete template ${name}`);
      if (r == false) {
        return;
      } 

      this.isLoading = true;
      const reply = await this.templatesService.DeleteTemplate(uuid);
      if (reply) {
        this.GetTemplates();
        this.alertService.success("Template successfully deleted.");
      } else {
        console.error("message-template", "Fail to delete template");
        this.alertService.error(`Fail to delete template`);
      }
    } catch (error) {
      console.error("MessageTemplatesComponent", "DeleteTemplate", error);
      this.alertService.error(`Fail to delete template`);
    } finally {
      this.isLoading = false;
    }
  };
}
