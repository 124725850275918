
export class Colleague {
  public user: any;

  public isLoading: boolean = false;

  constructor(user: any,private workspaceService:any,private alertService:any,private loadMembers:Function) {
    this.user = user;
  }

  public async sendInvitations() {
    try {      

      this.isLoading = true;

      const reply = await this.workspaceService.sendInvitations(
        [this.user.username],
        this.user.ownerName,
        this.user.workspaceid,
        this.user.workSpaceName,this.user.memberid,this.user.user_type
      );
      if (reply) {        
        this.isLoading = false;
        this.alertService.success(`Invitation Successfully Send`);
        return;
      }
      this.isLoading = false;
      this.alertService.error(
        `Fail To Send Invitations or Already invited.[${this.user.username}]`
      );
    } catch (ex) {
      this.isLoading = false;
      this.alertService.error("Fail To Send Invitations or Already invited");
      console.error(ex);
    }
  }

  public async cancelInvitation() {
    try {      

      this.isLoading = true;//owner, workspaceid, workspacename, memberId
      const reply = await this.workspaceService.cancelInvitation(
        this.user.ownerName,
        this.user.workspaceid,
        this.user.workSpaceName,
        this.user.memberid
      );
      if (reply) {        
        this.isLoading = false;
        this.alertService.success(`Successfully Canceled Invitation. [${this.user.username}]`);
        this.loadMembers(1);
        return;
      }
      this.isLoading = false;
      this.alertService.error(
        `Fail To Cancel Invitation.[${this.user.username}]`
      );
    } catch (ex) {
      this.isLoading = false;
      this.alertService.error("Fail To Cancel Invitation");
      console.error(ex);
    }
  }

  public async removeFromWorkspace() {
    try {      

      this.isLoading = true;
      const reply = await this.workspaceService.removeFromWorkspace(
        this.user.ownerName,
        this.user.workspaceid, this.user.workSpaceName,
        this.user.memberid,this.user.userid,this.user.username
      );
      if (reply) {        
        this.isLoading = false;
        this.alertService.success(`${this.user.username} Removed From Workspace`);
        this.loadMembers(1)
        return;
      }
      this.isLoading = false;
      this.alertService.error(
        `Fail To Remove From workspace.[${this.user.username}]`
      );
    } catch (ex) {
      this.isLoading = false;
      this.alertService.error("Fail To Remove From workspace");
      console.error(ex);
    }
  }


  public async acceptRequest() {
    try {      

      this.isLoading = true;
      const reply = await this.workspaceService.acceptJoinRequest(
        this.user.workspaceid, this.user.requester_id,"agent" );
      if (reply) {        
        this.isLoading = false;
        this.alertService.success(`User ${this.user.username} Join Request to Domain ${this.user.workSpaceName} Accepted.`);
        this.loadMembers(1)
        return;
      }
      this.isLoading = false;
      this.alertService.error(
        `Fail To Accept User ${this.user.username} Domain ${this.user.workSpaceName} Join request`
      );
    } catch (ex) {
      this.isLoading = false;
      this.alertService.error("Fail To Accept User join Request");
      console.error(ex);
    }
  }

  public async deleteJoinRequest() {
    try {      

      this.isLoading = true;
      const reply = await this.workspaceService.deleteJoinRequest(this.user.workspaceid, this.user.requester_id);
      if (reply) {        
        this.isLoading = false;
        this.alertService.success(`User ${this.user.username} Join Request to Domain ${this.user.workSpaceName} Accepted.`);
        this.loadMembers(1)
        return;
      }
      this.isLoading = false;
      this.alertService.error(
        `Fail To Accept User ${this.user.username} Domain ${this.user.workSpaceName} Join request`
      );
    } catch (ex) {
      this.isLoading = false;
      this.alertService.error("Fail To Accept User join Request");
      console.error(ex);
    }
  }

}
