import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BasicConfig} from '../../../config/constants';
import {environment} from '../../../../environments/environment';
import { PackageService } from "../../../service/package/package.service";
import { AlertService } from "../../../_alert";
import { NgxSpinnerService  } from "ngx-spinner";

class BasePackageUnit {
  public UnitType:string;
  public UnitOfMeasurement:string;
  public Quantity:number;
  public Description:string;
  public Features:string[]=[]
}
class basePackage{
  public isCurrentPackage:boolean=false;
  public Id:string;
  public PackageName:string;
  public Description:string;
  public BaseValue:number;
  public CurrencyUnit:string;
  public BasePackageUnits:BasePackageUnit[] = [];
  public Features:string[]=[
    "Public and private conversations",
    "Searchable history",
    "Group video calls",
    "Voice messages",
    "Screen sharing"
  ];
 }

@Component({
  selector: 'app-package-buying',
  templateUrl: './package-buying.component.html',
  styleUrls: ['./package-buying.component.scss']
})
export class PackageBuyingComponent implements OnInit {

  private basicConfig: any;  
  private consoleUrl: string;
  public isLoading:boolean = false;
  public packageDetails:basePackage[]=[];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private packageService:PackageService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService
    ) {
      this.basicConfig = BasicConfig;
    this.consoleUrl = environment.TCMConsole;
     }

  ngOnInit() {
    this.loadBasePackageList();
  }

  private async loadBasePackageList(){
    let dataSet = [];
    try {
      this.spinner.show();
      this.isLoading = true;
     const data = await Promise.all([this.packageService.getBasePackages(),this.packageService.getCurrentPackages()]);
     const response = data[0];
     const currentPackage = data[1];
     
     if(response){
       response.map((p)=>{
         if(p){
          const basePak = new basePackage();
          basePak.isCurrentPackage = false;
          if(currentPackage && currentPackage.ActiveBasePackages){
            const d =  currentPackage.ActiveBasePackages.find( cp=>  cp.BasePackageId === p.Id);
            basePak.isCurrentPackage = d !== undefined;
          }          
          basePak.Id = p.Id;
          basePak.PackageName = p.PackageName;
          basePak.Description = p.Description;
          basePak.BaseValue = p.BaseValue;
          basePak.CurrencyUnit = p.CurrencyUnit;
          if(p.BasePackageUnits){
            //basePak.BasePackageUnits = new BasePackageUnit[p.BasePackageUnits.length];
            p.BasePackageUnits.map((u)=>{
              const unit = new BasePackageUnit();
              unit.Description = u.Description;
              unit.UnitType =  u.UnitType;
              unit.UnitOfMeasurement = u.UnitOfMeasurement;
              unit.Quantity = u.Quantity;
              const unitOfMeasurement = u.UnitOfMeasurement[0].toUpperCase() + u.UnitOfMeasurement.substr(1).toLowerCase();
              unit.Features.push(`${u.UnitType[0].toUpperCase() + u.UnitType.substr(1).toLowerCase()} ${u.UnitType==="USERS"? unitOfMeasurement: u.Quantity} ${u.UnitType==="USERS"? u.Quantity: u.UnitOfMeasurement}`)
              basePak.BasePackageUnits.push(unit); 

            })
          }          
          dataSet.push(basePak);
         }
         
       })
     }
    } catch (error) {
      console.error("PackageBuyingComponent","loadBasePackageList",error);
      this.alertService.error("Fail To Load Package Details");
    }
    finally{
      //this.packageDetails = [];
      this.packageDetails = dataSet;
      this.spinner.hide();
      this.isLoading = false;
    }
  }

  public async buyBasePackage(id){
    try {
      this.spinner.show();
      const response = await this.packageService.buyBasePackage(id);
      if(response){
        await this.loadBasePackageList();
        this.alertService.success("Package Buying Process Completed");        
        return;
      }
      this.alertService.error("Fail To Buy Selected Package");
    } catch (error) {
      console.error("PackageBuyingComponent","buyBasePackage",error);
      this.alertService.error("Fail To Buy Selected Package");
    }
  }
  
}
