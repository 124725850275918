import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GuardianService } from "../../service/guardian/guardian.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_alert";
import { BasicConfig } from "../../config/constants";
import { async } from "@angular/core/testing";
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from "../../service/user/user.service";
import { UserAuthService } from "../../service/userAuth/user-auth.service";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import { Subscription } from "rxjs";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  public emailAddress: string = "";
  public passwordReset: FormGroup;
  private basicConfig: any;
  public isLoading: boolean = false;
  public isLinkSend: boolean = false;
  private subscription: Subscription;

  public submitted = false;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private guardianService: GuardianService,
    private alertService: AlertService,
    private userService: UserService,
    private userAuthService: UserAuthService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.basicConfig = BasicConfig;
  }

  ngOnInit() {
    this.passwordReset = this.formBuilder.group({
      emailAddress: [
        "",
        [
          Validators.required,
          Validators.pattern(this.basicConfig.emailPattern),
          Validators.email,
          Validators.minLength(7),
          Validators.maxLength(50),
        ],
      ],
    });

    /* this.subscription = this.recaptchaV3Service.onExecute.subscribe(
      (data: OnExecuteData) => {
        this.resetPasswordExecute(data.action, data.token);
      }
    ); */
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get f() {
    return this.passwordReset.controls;
  }

  onReset() {
    this.submitted = false;
    this.passwordReset.reset();
  }

  /* public resetPassword = () => {
    this.recaptchaV3Service.execute("importantAction");
  }; */
  public resetPassword = async () => {
    try {
      this.submitted = true;
      
      // stop here if form is invalid
      if (this.passwordReset.invalid) {
        return;
      }
     
      this.isLoading = true;
      let token = await this.recaptchaV3Service.execute('isEmailExisting').toPromise();
      if (
        await this.guardianService.isEmailExisting(
          this.passwordReset.value.emailAddress,
          token
        )
      ) {
        token = await this.recaptchaV3Service.execute('passwordReset').toPromise();
        if (
          await this.guardianService.passwordReset(
            this.passwordReset.value.emailAddress,
            token
          )
        ) {
          this.isLinkSend = true;
          this.alertService.success(
            `Password Reset Link Send To ${this.passwordReset.value.emailAddress}`
          );

          return;
        }
        this.isLoading = false;
        this.alertService.error(
          `Fail To Send Password Reset Link To ${this.passwordReset.value.emailAddress}`
        );
        return;
      }
      this.isLoading = false;
      this.alertService.error(
        `Invalidate Email Address ${this.passwordReset.value.emailAddress}`
      );
    } catch (e) {
      this.isLoading = false;
      console.error(
        `Fail To Send Password Reset Link To ${this.passwordReset.value.emailAddress}`
      );
      return false;
    }
  };
  public async goToPage(menu) {
    switch (menu) {
      case "home":
        this.router.navigate(["/home"]);
        break;
        case "signin":
          this.router.navigate(["/signIn"]);
          break;
      default:
        break;
    }
  }
}
