import { Component, OnInit } from '@angular/core';
import { LoginHandlerService } from "../../../service/auth/login-handler.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { from } from "rxjs";
import { AlertService } from "../../../_alert";
import { ActivatedRoute, Router } from "@angular/router";
import { BasicConfig } from "../../../config/constants";
import { GuardianService } from "../../../service/guardian/guardian.service";
import { NgxSpinnerService } from "ngx-spinner";
import { UserAuthService } from "../../../service/userAuth/user-auth.service";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";

@Component({
  selector: 'app-user-creations',
  templateUrl: './user-creations.component.html',
  styleUrls: ['./user-creations.component.scss']
})
export class UserCreationsComponent implements OnInit {

  public wp = { workSpaceName: null, workspaceid: null };
  public profileAdd: FormGroup;

  public displayName: string;
  public userType: string = "0";
  public firstName: string = "";
  public lastName: string = "";
  public mobileNumber: string = "";
  public emailAddress: string = "";
  private emailAddressDum: string = "";
  public password: string = "";
  public confirmPassword: string = "";
  public isUserExists: boolean = false;
  public isLoading: boolean = false;
  public passwordStrength = {
    float: "left",
    width: "100px",
    height: "25px",
    "margin-left": "5px",
    "background-color": "white",
  };

  public basicConfig: any;
  public submitted:boolean=false;
  public passwordPolicyNames:string="";

  constructor(
    private formBuilder: FormBuilder,
    private loginHandlerService: LoginHandlerService,
    private alertService: AlertService,  private router: Router,    
    private userAuthService: UserAuthService,
    private recaptchaV3Service: ReCaptchaV3Service,    
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private guardianService: GuardianService,
  ) {
    this.basicConfig = BasicConfig;
  }

  ngOnInit() {
    this.wp = this.loginHandlerService.GetWorkspace();
    const passwordPolicy = this.loginHandlerService.GetPasswordPolicy()||this.basicConfig.pwdPattern;
    this.passwordPolicyNames = this.loginHandlerService.GetPasswordPolicyNames();
    this.profileAdd = this.formBuilder.group(
      {
        firstName: [
          "",
          [
            Validators.required,
            Validators.pattern(this.basicConfig.namePattern),
            Validators.minLength(3),
            Validators.maxLength(25),
          ],
        ],
        lastName: [
          "",
          [
            Validators.required,
            Validators.pattern(this.basicConfig.namePattern),
            Validators.minLength(3),
            Validators.maxLength(15),
          ],
        ],
        mobileNumber: [
          "",
          [
            Validators.required,
            Validators.pattern(this.basicConfig.mobileNumberPattern),
            Validators.minLength(7),
            Validators.maxLength(15),
          ],
        ],
        emailAddress: [
          "",
          [
            Validators.required,
            Validators.pattern(this.basicConfig.emailPattern),
            Validators.email,
            Validators.minLength(7),
            Validators.maxLength(50),
          ],
        ],
        password: [
          "", 
          [
            Validators.required, 
            Validators.minLength(this.basicConfig.passwordLength.minLength),
            Validators.maxLength(this.basicConfig.passwordLength.maxLength),
            Validators.pattern(this.basicConfig.pwdPattern)
          ]
        ],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: this.MustMatch("password", "confirmPassword"),
      }
    );
  }

  private MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  get f() {
    return this.profileAdd.controls;
  }

  onReset() {
    
    this.profileAdd.reset();
    this.submitted = false;
    
  }  

  private async pushProfileToUserAccount(profileData,token) {
    try {
      return await this.userAuthService.saveUserProfile(
        profileData,token,profileData.emailAddress
      );
    } catch (error) {
      console.error("pushProfileToUserAccount", error);
      return false;
    }
  }

  public async saveProfile() {
    try {
      
      this.submitted = true;

      // stop here if form is invalid
      if (this.profileAdd.invalid) {
        return;
      }

      this.isLoading = true;

      let profileData = {
        emailAddress: this.profileAdd.value.emailAddress.toLowerCase(),
        displayName: `${this.profileAdd.value.firstName} ${this.profileAdd.value.lastName}`,
        firstName: this.profileAdd.value.firstName,
        lastName: this.profileAdd.value.lastName,
        password: this.profileAdd.value.password,
        mobileNumber: this.profileAdd.value.mobileNumber,
      };

      let token = await this.recaptchaV3Service.execute('pushProfileToUserAccount').toPromise();
      // create User Profile
      if (await this.pushProfileToUserAccount(profileData,token)) {
        token = await this.recaptchaV3Service.execute('saveIdentity').toPromise();
        // create auth account
        if (          
          await this.guardianService.saveIdentity(
            profileData.emailAddress,
            profileData.password,token
          )
        ) {
          this.alertService.success(`Profile Created`);
          this.onReset();
          this.isLoading = false;
        } else {
          console.error("Fail to Create Auth User");
          this.alertService.error(`Fail to Create Auth Account.`);
        }
      } else {
        console.error("Fail to Create User Profile");
        this.alertService.error("Fail to Create User Profile");
      }
    } catch (error) {
      this.alertService.error("Fail to Create Account");
      console.error("saveProfile", error);
    }
  }
}
