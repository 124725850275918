
import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { WorkspaceService } from "../../../service/workspace/workspace.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginHandlerService } from "../../../service/auth/login-handler.service";
import { AlertService } from "../../../_alert";
import {  Router } from "@angular/router";
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { SimpleModalService } from 'ngx-simple-modal';

@Component({
  selector: 'app-role-detail',
  templateUrl: './role-detail.component.html',
  styleUrls: ['./role-detail.component.scss']
})
export class RoleDetailComponent implements OnInit {

  mypage = 1;
  mypageSize = 5;
  public isLoading:boolean = false;
  public submitted:boolean = false;
  public userRoleDetails = [];  
  public profileAdd: FormGroup;
  public wp = { workSpaceName: null, workspaceid: null };
  public selectedRow = null;
  
  constructor(private SimpleModalService: SimpleModalService,private router: Router,private alertService: AlertService, private loginHandlerService: LoginHandlerService, private formBuilder: FormBuilder,private workspaceService : WorkspaceService) { }

  ngOnInit() {
    
    this.wp = this.loginHandlerService.GetWorkspace();
    this.getUserRoleDetails();
    this.profileAdd = this.formBuilder.group(
      {
        role: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(20),
          ],
        ],
        description: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(50),
          ],
        ],        
      }
    );
  }

  public getUserRoleDetails = async()=>{
    try {
      this.isLoading = true;
      this.userRoleDetails = [];
      const response = await this.workspaceService.getUserRolesWithFeatures(this.wp.workspaceid);
      if(response){
        this.userRoleDetails = response;
      }
    } catch (error) {
      console.error("getUserRoleDetails",error);
    }finally{
      this.isLoading = false;
    }
  }

  get f() {
    return this.profileAdd.controls;
  }

  onReset() {
    
    this.profileAdd.reset();
    this.submitted = false;
    
  } 

  public saveProfile = async()=>{
    try {
      
      this.submitted = true;

      // stop here if form is invalid
      if (this.profileAdd.invalid) {
        return;
      }      
      this.isLoading = true;

      
      const response = await this.workspaceService.saveUserRole(this.profileAdd.value.role,this.profileAdd.value.description,this.wp.workspaceid);
      if(response === true){
        this.alertService.success(`User role successfully saved`);
        this.onReset();
        this.getUserRoleDetails();
      }else{
        this.alertService.warn(`Fail to save user role`);
      }
      
    } catch (error) {
      console.error("saveProfile", error);
      this.alertService.error(`Fail to save user role`);
    }finally{
      this.isLoading = false;
    }
  }

  public mapUserToRole = (details)=>{
    try {
      this.router.navigate(["/console/configurations/role",details.role,"users"]);
      //this.router.navigate(["/console/configurations/userrolemap"],  {state: {data: {...details}},queryParams: { role: details.role } });
    } catch (error) {
      console.error("mapUserToRole", error);
    }
  }

  public mapfeaturesToRole = (details)=>{
    try {
      this.router.navigate(["/console/configurations/role",details.role,"features"]);
      //this.router.navigate(["/console/configurations/userrolemap"],  {state: {data: {...details}},queryParams: { role: details.role } });
    } catch (error) {
      console.error("mapUserToRole", error);
    }
  }
  
  public rowSelectionChanged = (id)=>{
    this.selectedRow = id;
  }

  public addNewRestriction = (feature_details,role_details)=>{
    try {
      //uRoleDetails.role.replace(this.wp.workSpaceName + '_','')
      this.router.navigate(["/console/configurations/role/",role_details.role.replace(this.wp.workSpaceName + '_',''),"feature",feature_details.feature_name,feature_details.id,"restrictions"]);
    } catch (error) {
      console.error("mapUserToRole", error);
    }
  }

  public deleteRole = (role) => {
    const displayRole = role.replace(this.wp.workSpaceName + '_','');
    this.SimpleModalService.addModal(ConfirmDialogComponent, {
      title: 'Confirmation',
      message: `You won't be able to revert this. Do you really want to delete role ${displayRole}?`})
      .subscribe(async(isConfirmed) => {
        if(isConfirmed === true){
          const response = await this.workspaceService.deleteRole(role);
          if(response === true){
            this.alertService.success(`Role : ${displayRole} successfully deleted`);
            this.getUserRoleDetails();
          }else{
            this.alertService.warn(`Fail to delete role : ${displayRole}`);
          }
        }
    });
  }
}
