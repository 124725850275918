import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  public activeMenu: string = "packages";
  constructor(private router: Router) {}

  ngOnInit() {
    this.goToPage("packages");
  }

  public async goToPage(menu) {
    switch (menu) {
      case "rm":
        this.router.navigate(["/console/settings/rm"]);
        this.activeMenu = "rm";
        break;
      case "statuscode":
        this.router.navigate(["/console/settings/status"]);
        this.activeMenu = "statuscode";
        break;
      case "report":
        this.router.navigate(["/console/report"]);
        this.activeMenu = "report";
        break;
      case "passwordpolicy":
        this.router.navigate(["/console/settings/passwordpolicy"]);
        this.activeMenu = "passwordpolicy";
        break;
      case "messagetemplates":
        this.router.navigate(["/console/settings/messagetemplates"]);
        this.activeMenu = "messagetemplates";
        break;
      case "packages":
        this.router.navigate(["/console/settings/packages"]);
        this.activeMenu = "packages";
        break;
      default:
        break;
    }
  }
}
