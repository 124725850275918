import { Injectable } from "@angular/core";
import { GuardianService } from "../guardian/guardian.service";
import { from } from "rxjs";
import { async } from "@angular/core/testing";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { WorkspaceService } from "../../service/workspace/workspace.service";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import { BasicConfig } from "../../config/constants";

@Injectable({
  providedIn: "root",
})
export class LoginHandlerService {
  private TOKEN_KEY: string = "@@user";
  private workspaceNameKey: string = "@@organizationName---";
  private workspaceKey: string = "@@organization---";
  private workspacesKey: string = "@@organizations---";
  private refreshTokenKey: string = "@@@refreshToken-----";
  private userNameKey: string = "@@@userName-----";
  private passwordPolicyKey: string = "@@@passwordpolicy-----";
  private passwordPolicyNamesKey: string = "@@@passwordpolicyNames-----";
  private basicConfig: any;
  constructor(
    protected guardianService: GuardianService,
    private workspaceService: WorkspaceService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.basicConfig = BasicConfig;
  }

  private setWorkspace = (organization, userName) => {
    if (organization) this.setWorkspaceName(organization.workSpaceName);
    sessionStorage.setItem(
      this.workspaceKey,
      JSON.stringify({ organization, userName })
    );
  };

  private getWorkspace = () => {
    const wdata = JSON.parse(sessionStorage.getItem(this.workspaceKey));
    return wdata ? wdata.organization : null;
  };

  private setWorkspaces = (organizations): void => {
    sessionStorage.setItem(this.workspacesKey, JSON.stringify(organizations));
  };

  private getWorkspaces = (): any[] => {
    return JSON.parse(sessionStorage.getItem(this.workspacesKey));
  };

  private setWorkspaceName = (organization) => {
    sessionStorage.setItem(this.workspaceNameKey, organization);
  };

  private getWorkspaceName = () => {
    return sessionStorage.getItem(this.workspaceNameKey);
  };

  private setRefreshToken = (refreshToken) => {
    sessionStorage.setItem(this.refreshTokenKey, refreshToken);
  };

  private getRefreshToken = () => {
    return sessionStorage.getItem(this.refreshTokenKey);
  };

  private setToken = (token) => {
    sessionStorage.setItem(this.TOKEN_KEY, token);
  };

  private getToken = () => {
    const token = sessionStorage.getItem(this.TOKEN_KEY);
    return token;
  };

  private setUserName = (userName) => {
    sessionStorage.setItem(this.userNameKey, userName);
  };

  private getUserName = () => {
    const userName = sessionStorage.getItem(this.userNameKey);
    return userName;
  };

  private revokeToken = async (organization, userName, refreshToken) => {
    const authInfo: any = await this.guardianService.getToken(
      userName,
      refreshToken,
      organization
    );
    if (authInfo.isSuccess) {
      this.setToken(authInfo.tokenInfo.token);
      const response = this.getWorkspaces();
      if (response)
        this.setWorkspaces(
          response.map((item: any) => {
            item.default = null;
            if (item.workSpaceName === organization) {
              item.default = item.workSpaceName;
              this.setWorkspace(item, userName);
            }
            return item;
          })
        );
      return true;
    }
  };

  private logout() {
    sessionStorage.clear();
    console.log(this.GetUserName());
  }

  private getPasswordPolicy = async (userName) => {
    try {
      let token = await this.recaptchaV3Service
        .execute("checkWorkspaceNameAvailability")
        .toPromise();
      let reply = await this.workspaceService.getPasswordPolicy(
        userName,
        token
      );
      if (reply) {
        const policy = reply["passwordPolicy"];
        this.SetPasswordPolicy(policy);
        this.SetPasswordPolicyNames(reply["policyNames"]);
        return policy;
      }
      return null;
    } catch (error) {
      console.error("getPasswordPolicy", error);
      return null;
    }
  };

  private async login(userName: string, userPassword: string) {
    this.logout();
      

    const response: any = await this.guardianService.login(
      userName,
      userPassword
    );
    if (response.isSuccess) {      
      this.setToken(response.tokenInfo.token);
      this.setRefreshToken(response.tokenInfo.refreshToken);
      this.setUserName(userName);
      //this.setWorkspaceName(response.tokenInfo.organization);
      this.setWorkspaces(response.tokenInfo.organizations);
      // this.setWorkspace( response.tokenInfo.organizations[0], userName);
      /* response.tokenInfo.organizations.map((item: any) => {
        if (item.workSpaceName === response.tokenInfo.organization) {
          item.default = item.workSpaceName;
          this.setWorkspace(item, userName);
        }
      }); */
      if (
        response.tokenInfo.organizations &&
        response.tokenInfo.organizations[0]
      )
        await this.RevokeToken(
          response.tokenInfo.organizations[0].workSpaceName
        );
      return {
        isSuccess: true,
      };
    } else {
      return {
        isSuccess: false,
      };
    }
  }

  public async RevokeToken(organization: string) {
    const reply = await this.revokeToken(
      organization,
      this.getUserName(),
      this.getRefreshToken()
    );

    const policy = await this.getPasswordPolicy(this.getUserName()); 

    return reply;
  }

  public async Login(userName: string, userPassword: string) {
    return await this.login(userName, userPassword);
  }

  public async Logout() {
    return this.logout();
  }

  public SetWorkspaces(workspaces) {
    return this.setWorkspaces(workspaces);
  }
  public GetWorkspaces() {
    return this.getWorkspaces();
  }

  public GetUserName() {
    return this.getUserName();
  }
  public GetWorkspaceName() {
    return this.getWorkspaceName();
  }
  public GetWorkspace() {
    return this.getWorkspace();
  }
  public GetToken() {
    return this.getToken();
  }

  public SetPasswordPolicyNames = (passwordPolicyNames) => {
    if(!passwordPolicyNames)
    passwordPolicyNames = this.basicConfig.pwdPatternNames;
    sessionStorage.setItem(this.passwordPolicyNamesKey, passwordPolicyNames);
  };

  public GetPasswordPolicyNames = () => {
    const passwordPolicy = sessionStorage.getItem(this.passwordPolicyNamesKey);
    return passwordPolicy;
  };

  public SetPasswordPolicy = (passwordPolicy) => {
    if(!passwordPolicy)
    passwordPolicy = this.basicConfig.pwdPattern;
    sessionStorage.setItem(this.passwordPolicyKey, passwordPolicy);
  };

  public GetPasswordPolicy = () => {
    const passwordPolicy = sessionStorage.getItem(this.passwordPolicyKey);
    return passwordPolicy;
  };
}
