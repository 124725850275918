import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BasicConfig} from '../../config/constants';
import {environment} from '../../../environments/environment';
import { PackageService } from "../../service/package/package.service";


import {async} from '@angular/core/testing';

class BasePackageUnit {
  public UnitType:string;
  public UnitOfMeasurement:string;
  public Quantity:number;
  public Description:string;
  public Features:string[]=[]
}
class basePackage{
  public Id:string;
  public PackageName:string;
  public Description:string;
  public BaseValue:number;
  public CurrencyUnit:string;
  public BasePackageUnits:BasePackageUnit[] = [];
  public Features:string[]=[
    "Public and private conversations",
    "Searchable history",
    "Group video calls",
    "Voice messages",
    "Screen sharing"
  ];
 }


@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss'],
})
export class StaticPageComponent implements OnInit {
  public emailAddress: string;
  public registerForm;
  public submitted = false;
  public environment = environment;
  public packageDetails:basePackage[]=[];


  private basicConfig: any;  
  private consoleUrl: string;
  

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private packageService:PackageService
  ) {
    this.basicConfig = BasicConfig;
    this.consoleUrl = environment.TCMConsole;
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      emailAddress: [
        '',
        [
          Validators.required,
          Validators.pattern(this.basicConfig.emailPattern),
          Validators.email,
          Validators.minLength(7),
          Validators.maxLength(50),
        ],
      ],
    });

    this.loadBasePackageList();
  }


  get f() {
    return this.registerForm.controls;
  }

  private async loadBasePackageList(){
    try {
     const response = await this.packageService.getBasePackages();
     if(response){
       response.map((p)=>{
         if(p){
          const basePak = new basePackage();
          basePak.Id = p.Id;
          basePak.PackageName = p.PackageName;
          basePak.Description = p.Description;
          basePak.BaseValue = p.BaseValue;
          basePak.CurrencyUnit = p.CurrencyUnit;
          if(p.BasePackageUnits){
            //basePak.BasePackageUnits = new BasePackageUnit[p.BasePackageUnits.length];
            p.BasePackageUnits.map((u)=>{
              const unit = new BasePackageUnit();
              unit.Description = u.Description;
              unit.UnitType =  u.UnitType;
              unit.UnitOfMeasurement = u.UnitOfMeasurement;
              unit.Quantity = u.Quantity;
              const unitOfMeasurement = u.UnitOfMeasurement[0].toUpperCase() + u.UnitOfMeasurement.substr(1).toLowerCase();
              unit.Features.push(`${u.UnitType[0].toUpperCase() + u.UnitType.substr(1).toLowerCase()} ${u.UnitType==="USERS"? unitOfMeasurement: u.Quantity} ${u.UnitType==="USERS"? u.Quantity: u.UnitOfMeasurement}`)
              basePak.BasePackageUnits.push(unit); 

            })
          }          
          this.packageDetails.push(basePak);
         }
         
        console.log(JSON.stringify(this.packageDetails));
       })
     }
    } catch (error) {
      console.error("StaticPageComponent","loadBasePackageList",error);
    }
  }

  public async goToPage(menu) {
    switch (menu) {
      case 'creatAccount':
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
          return;
        }
        this.router.navigate([
          'signup/register',
          {email: this.registerForm.value.emailAddress},
        ]);
        break;
      case 'signIn':
        this.router.navigate(['/signIn']);
        break;
      case 'signup':
        this.router.navigate(['/getStart']);
        break;
      case 'agent':
        window.open(this.consoleUrl,"_self");
          break;
      default:
        break;
    }
  }

  
}
