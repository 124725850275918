import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Injectable({
  providedIn: "root",
})
export class PackageService {
  baseUrl: string;
  constructor(
    private httpClient: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.baseUrl = environment.baseUrlPackage;
  }

  public async getBasePackages() {
    try {
      let token = await this.recaptchaV3Service
        .execute("GetBasePackages")
        .toPromise();
      const reply = await this.httpClient
        .get(`${this.baseUrl}/packages/base?g-recaptcha-response=${token}`)
        .toPromise();
      return reply && reply["isSuccess"] ? reply["result"] : null;
    } catch (error) {
      console.error("PackageService", "getBasePackages", error);
      return null;
    }
  }

  public async getCurrentPackages() {
    try {      
      const reply = await this.httpClient
        .get(`${this.baseUrl}/packages/current`)
        .toPromise();
      return reply && reply["isSuccess"] ? reply["result"] : null;
    } catch (error) {
      console.error("PackageService", "getBasePackages", error);
      return null;
    }
  }

  public async buyBasePackage(packageId) {
    try {      
      const reply = await this.httpClient
      .post(`${this.baseUrl}/package/base/${packageId}/buy`, {}, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
      return reply && reply["isSuccess"] === true;
    } catch (error) {
      console.error("PackageService", "buyBasePackage", error);
      return false;
    }
  }

  public async buyDefaultBasePackage(workspaceName,userId=null) {
    try {      
      const reply = await this.httpClient
      .post(`${this.baseUrl}/package/default/${workspaceName}/buy`,JSON.stringify({userId:userId}), {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
      return reply && reply["isSuccess"] === true;
    } catch (error) {
      console.error("PackageService", "buyDefaultBasePackage", error);
      return false;
    }
  }

  


  
  
}
