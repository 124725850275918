import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BasicConfig} from '../../config/constants';

import {async} from '@angular/core/testing';

@Component({
  selector: 'app-try-now',
  templateUrl: './try-now.component.html',
  styleUrls: ['./try-now.component.scss']
})
export class TryNowComponent implements OnInit {
  public emailAddress: string;
  public registerForm;
  private basicConfig: any;
  public submitted = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.basicConfig = BasicConfig;
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      emailAddress: [
        '',
        [
          Validators.required,
          Validators.pattern(this.basicConfig.emailPattern),
          Validators.email,
          Validators.minLength(7),
          Validators.maxLength(50),
        ],
      ],
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  public async goToPage(menu) {
    switch (menu) {
      case 'creatAccount':
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
          return;
        }
        this.router.navigate([
          'signup/register',
          {email: this.registerForm.value.emailAddress},
        ]);
        break;
      case 'signIn':
        this.router.navigate(['/signIn']);
        break;
      case 'signup':
        this.router.navigate(['/getStart']);
        break;
      case 'home':
        this.router.navigate(['/']);
        break;
      default:
        break;
    }
  }
}
