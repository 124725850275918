import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GuardianService } from "../../service/guardian/guardian.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../_alert";
import { BasicConfig } from "../../config/constants";
import { async } from "@angular/core/testing";
import { NgxSpinnerService } from "ngx-spinner";
import { UserAuthService } from "../../service/userAuth/user-auth.service";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import { LoginHandlerService } from "../../service/auth/login-handler.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  public displayName: string;
  public userType: string = "0";
  public firstName: string = "";
  public lastName: string = "";
  public mobileNumber: string = "";
  public emailAddress: string = "";
  private emailAddressDum: string = "";
  public password: string = "";
  public confirmPassword: string = "";
  public isUserExists: boolean = false;
  public isLoading: boolean = false;
  public passwordStrength = {
    float: "left",
    width: "100px",
    height: "25px",
    "margin-left": "5px",
    "background-color": "white",
  };
  public registerForm: FormGroup;
  public basicConfig: any;
  public submitted = false;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private guardianService: GuardianService,
    private alertService: AlertService,
    private userAuthService: UserAuthService,private loginHandlerService: LoginHandlerService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.basicConfig = BasicConfig;
  }

  ngOnInit() {
    const passwordPolicy = this.basicConfig.pwdPattern;

    this.registerForm = this.formBuilder.group(
      {
        /*  displayName: ["", Validators.required],
        emailAddress: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", [Validators.required, Validators.minLength(6)]] */

        firstName: [
          "",
          [
            Validators.required,
            Validators.pattern(this.basicConfig.namePattern),
            Validators.minLength(3),
            Validators.maxLength(25),
          ],
        ],
        lastName: [
          "",
          [
            Validators.required,
            Validators.pattern(this.basicConfig.namePattern),
            Validators.minLength(3),
            Validators.maxLength(15),
          ],
        ],
        mobileNumber: [
          "",
          [
            Validators.required,
            Validators.pattern(this.basicConfig.mobileNumberPattern),
            Validators.minLength(7),
            Validators.maxLength(15),
          ],
        ],
        emailAddress: [
          "",
          [
            Validators.required,
            Validators.pattern(this.basicConfig.emailPattern),
            Validators.email,
            Validators.minLength(7),
            Validators.maxLength(50),
          ],
        ],
        password: [
          "", 
          [
            Validators.required, 
            Validators.minLength(this.basicConfig.passwordLength.minLength),
            Validators.maxLength(this.basicConfig.passwordLength.maxLength),
            Validators.pattern(passwordPolicy)
          ]
        ],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: this.MustMatch("password", "confirmPassword"),
      }
    );

    /*  if (this.route.params) {
      this.route.params.subscribe((params) => {
        this.registerForm.get("emailAddress").setValue(params.emailAddress);
      });
    } */
    
    const profileData = window.history.state.data;
    if (profileData && profileData.emailAddress) {
      this.registerForm.get("emailAddress").setValue(profileData.emailAddress);
      this.emailAddressDum = profileData.emailAddress;
    } else {
      this.alertService.error("No profile data");
      this.sendToHome();
    }

  }
  
  public saveProfile(): void {
    this.submitted = true;

      // stop here if form is invalid
      if (this.registerForm.invalid) {
        return;
      }
      this.saveProfileExecute();
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  private MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();

    setTimeout(() => {
      this.registerForm.get("emailAddress").setValue(this.emailAddressDum);
    }, 1000);    
  }

  private async pushProfileToUserAccount(profileData,token) {
    try {
      const reply = await this.userAuthService.saveUserProfile(
        profileData,token,profileData.emailAddress
      );

      this.profileData.userId = reply["data"]["userId"];
      return {
        isSuccess: reply && reply["status"] === 0,
        data: reply && reply["status"] === 0 ? reply["data"] : null,
      };
    } catch (error) {
      console.error("pushProfileToUserAccount", error);
      return false;
    }
  }

  private profileData = null;

  public async saveProfileExecute() {
    try {
      
      this.isLoading = true;

      this.profileData = {
        emailAddress: this.registerForm.value.emailAddress.toLowerCase(),
        displayName: `${this.registerForm.value.firstName} ${this.registerForm.value.lastName}`,
        firstName: this.registerForm.value.firstName,
        lastName: this.registerForm.value.lastName,
        password: this.registerForm.value.password,
        mobileNumber: this.registerForm.value.mobileNumber,
      };

      let token = await this.recaptchaV3Service.execute('pushProfileToUserAccount').toPromise();
      // create User Profile
      if (await this.pushProfileToUserAccount(this.profileData,token)) {
        token = await this.recaptchaV3Service.execute('saveIdentity').toPromise();
        // create auth account
        if (          
          await this.guardianService.saveIdentity(
            this.profileData.emailAddress,
            this.profileData.password,token
          )
        ) {
         
          this.router.navigate(["/signup/workspace"], {
            state: {
              data: {userId:this.profileData.userId, emailAddress: this.profileData.emailAddress,password: this.profileData.password, isSignup: true },
            },
          });
        } else {
          console.error("Fail to Create Auth User");
          this.alertService.error(`Fail to Create Auth Account.`);
          this.sendToHome();
        }
      } else {
        console.error("Fail to Create User Profile");
        this.alertService.error("Fail to Create User Profile");
        this.sendToHome();
      }
    } catch (error) {
      this.alertService.error("Fail to Create Account");
      console.error("saveProfile", error);
      this.sendToHome();
    }
  }

  private sendToHome() {
    setTimeout(() => {
      this.router.navigate(["/home"]);
    }, 3000);
  }

  public async goToPage(menu) {
    switch (menu) {
      case "home":
        this.router.navigate(["/home"]);
        break;
      case "forgotPwd":
        this.router.navigate(["/forgotPassword"]);
        break;
      case "signIn":
        this.router.navigate(["/signIn"]);
        break;
      case "emailVerification":
        this.router.navigate(["/emailVerification"]);
        break;
      case "userExists":
        this.isUserExists = !this.isUserExists;
        break;
      case "signWithParam":
        await this.router.navigate(["/signIn"], {
          state: {
            data: {
              emailAddress: this.registerForm.value.emailAddress.toLowerCase(),
              password: this.registerForm.value.password,
            },
          },
        });
        break;
      default:
        break;
    }
  }
}
