import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginHandlerService } from "../../../service/auth/login-handler.service";
import { AlertService } from "../../../_alert";
import { ActivatedRoute, Router } from "@angular/router";
import { CallReportsService } from "../../../service/callReports/call-reports.service";
import { RecorderServiceService } from "../../../service/recorder/recorder-service.service";
import * as moment from "moment";
import { NgbTimepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "../../../confirm-dialog/confirm-dialog.component";
import { SimpleModalService } from "ngx-simple-modal";
import { identifierModuleUrl } from "@angular/compiler";
import { BasicConfig } from "../../../config/constants";
import { environment } from "../../../../environments/environment";;


@Component({
  selector: "app-cdr-report",
  templateUrl: "./cdr-report.component.html",
  styleUrls: ["./cdr-report.component.scss"],
  providers: [NgbTimepickerConfig],
})
export class CdrReportComponent implements OnInit {
  private wp = { workSpaceName: null, workspaceid: null };
  private baseUrl: string;
  private basicConfig: any;
  private overwriteCallRecorderUrl:boolean = false;
  public maxDate: Date = new Date();

  private dataTimeNow = moment(this.maxDate, 'yyyy-mm-dd HH:mm:ss');
  private time: NgbTimeStruct = {hour: this.dataTimeNow.hours(), minute: this.dataTimeNow.minutes(), second: this.dataTimeNow.seconds()};   
  public startDate = null;
  public endDate = null;
  public startTime = this.time;
  public endTime = this.time;
  public callIds = undefined;
  public model;
  public mypage = 1;
  public mypageSize = 10;
  public myCollectionSize = 0;
  public isLoading: boolean = false;
  public submitted: boolean = false;
  public profileAdd: FormGroup;
  public conversations = [];
  public conversationsLoaded = [];
  public participants = {};
  public viewMoreId: any = 0;
  public activeCallDetails: any = {};
  public isCollapsed = true;
  files: File[] = [];

  data = {
    "simple key": "simple value",
    numbers: 1234567,
    "simple list": ["value1", 22222, "value3"],
    "special value": undefined,
    owner: null,
    "simple obect": {
      "simple key": "simple value",
      numbers: 1234567,
      "simple list": ["value1", 22222, "value3"],
      "simple obect": {
        key1: "value1",
        key2: 22222,
        key3: "value3",
      },
    },
  };
  constructor(
    private SimpleModalService: SimpleModalService,
    private recorderServiceService: RecorderServiceService,
    config: NgbTimepickerConfig,
    private router: Router,
    private alertService: AlertService,
    private loginHandlerService: LoginHandlerService,
    private formBuilder: FormBuilder,
    private callReportsService: CallReportsService
  ) {
    config.seconds = true;
    config.spinners = false;
    this.basicConfig = BasicConfig;
    this.overwriteCallRecorderUrl = JSON.parse(environment.overwriteCallRecorderUrl);
    this.baseUrl = environment.baseUrlCallRecorderApi;
   }

  ngOnInit() {
    this.wp = this.loginHandlerService.GetWorkspace();
    this.profileAdd = this.formBuilder.group(
      {
        dataRange: [
          "",
          [
            Validators.required,
          ],
        ]     
      }
    );
  }

  get f() {
    return this.profileAdd.controls;
  }

  onReset() {
    this.profileAdd.reset();
  }

  public toggle = () => {
    this.isCollapsed = !this.isCollapsed;
    this.startDate = null;
    this.endDate = null;
    this.callIds = undefined;
    this.files = [];
  };

  public showConfirm(id) {
    this.SimpleModalService.addModal(ConfirmDialogComponent, {
      title: "Confirmation",
      message: "Do you want to load records related to this clip?",
    }).subscribe((isConfirmed) => {
      if (isConfirmed === true) {
        this.callIds = id;
        this.loadConversations(id);
      }
    });
  }

  readFileAsText(file, basicConfig) {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();

      fr.onloadend = (e:any) => {
        try {
          if(e && e.target && e.target.result){
            const data = e.target.result;
            const ids = {};
            const cdata = basicConfig.callIds;
            const path  = cdata.json_path;
            const name  = cdata.json_object_name;

            /* const jData = JSON.parse(data);
            jData && jData[path].map((item)=>{
              const id = item&&item[name];
              if(id){
                ids[id] = id;
              }
            }); */

            const lines = data.split("\n");
            const headings = lines[0].split(',');
            lines[0] = undefined;
            const cid_index = headings.indexOf(name);
            lines.forEach( (line)=> {
              if( !line ) return; 
              const cid = line.split(',')[cid_index];
              ids[ cid ] = cid;
            });
            resolve(Object.keys(ids));
          }else{
            reject(null);
          }
        } catch (error) {
          reject(error);
        }
      };
      fr.onerror = function () {
        reject(fr);
      };
      fr.readAsText(file);
    });
  }

  loadJsonFiles = (files) => {
    try {
      /* const read_call_ids = (data)=>{        
        try {
          const jData = JSON.parse(data)
          const ids = {};
          const path  = this.basicConfig.callIds.json_path;
          const name  = this.basicConfig.callIds.json_object_name;
          jData && jData[path].map((item)=>{
            const id = item&&item[name];
            if(id){
              ids[id] = id;
            }
          });
          this.callIds = Object.keys(ids);
          console.log(jData);
        } catch (error) {
          
        }finally{
        }
      }

      files && files.forEach(file => {
        let fileReader: FileReader = new FileReader();
        fileReader.onloadend = (e) => {
          read_call_ids(e.target.result);                 
        }
        fileReader.readAsText(file);
      }); */

      let readers = [];

      // Abort if there were no files selected
      if (!files.length) {
        this.callIds = undefined;
        return;
      }

      files &&
        files.forEach((file) => {
          readers.push(this.readFileAsText(file, this.basicConfig));
        });

      Promise.all(readers).then((values) => {
        const ids = {};
        try {
          values &&
            Object.values(values).map((arr) => {
              const res = arr.reduce((a, b) => ((a[b] = b), a), ids);
              console.log(ids);
            });
        } catch (error) {
          console.error("loadJsonFiles", "Promise.all", error);
        } finally {
          this.callIds = Object.keys(ids);
        }
      });
    } catch (error) {
      console.error("loadJsonFiles", error);
    }
  };
  onSelect(event) {
    try {
      console.log(event);
      this.files.push(...event.addedFiles);
      this.loadJsonFiles(this.files);
    } catch (error) {
      console.error("onSelect", error);
    }
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    this.loadJsonFiles(this.files);
  }

  public convertToDateTimeFormat = (ticks) => {
    try {
      return new Date(parseInt(ticks));
    } catch (error) {}
  };

  public onStartTimeValueChange(bsValue: any): void {
    try {
      console.log(bsValue);
      if (bsValue === null) {
        this.dataTimeNow = moment(new Date(), "yyyy-mm-dd HH:mm:ss");
        this.startTime = {
          hour: this.dataTimeNow.hours(),
          minute: this.dataTimeNow.minutes(),
          second: this.dataTimeNow.seconds(),
        };
      }
    } catch (error) {
      console.error();
    }
  }

  public onEndTimeValueChange(bsValue: any): void {
    try {
      console.log(bsValue);
      if (bsValue === null) {
        this.dataTimeNow = moment(new Date(), "yyyy-mm-dd HH:mm:ss");
        this.endTime = {
          hour: this.dataTimeNow.hours(),
          minute: this.dataTimeNow.minutes(),
          second: this.dataTimeNow.seconds(),
        };
      }
    } catch (error) {
      console.error();
    }
  }

  public loadConversations = async (id = this.callIds) => {
    try {
      this.isLoading = true;
      //await this.getConversationsCount();
      this.mypage = 1;
      await this.getConversations(id);
    } catch (error) {
      console.error("loadConversations", error);
    }
  };

  public getConversations = async (callIds) => {
    try {
      this.isLoading = true;
      this.conversations = [];
      let startDateTime = this.startDate ? Date.parse(`${this.startDate.toDateString()} ${this.startTime.hour}:${this.startTime.minute}:${this.startTime.second}`) : undefined ;
      let endDateTime = this.endDate ? Date.parse(`${this.endDate.toDateString()} ${this.endTime.hour}:${this.endTime.minute}:${this.endTime.second}`) : null ;
      const response = await this.callReportsService.getConversations(this.mypage,this.mypageSize,startDateTime,endDateTime,callIds,this.wp.workspaceid);
      if(response && response.rows && response.rows.length > 0){
        this.myCollectionSize = response.count;
        this.conversations = response.rows;
        response.participants &&
          response.participants.map((p) => {
            this.participants[p.user_id] = p;
          });
      } else {
        this.alertService.warn(`Unable to load data`);
      }
    } catch (error) {
      console.error("getUserRoleDetails", error);
    } finally {
      this.isLoading = false;
    }
  };

  public calculateDurations = (cdr) => {
    try {
      const end = moment(new Date(cdr.endTime));
      const start = moment(new Date(cdr.startTime));
      const duration = moment.duration(end.diff(start));
      return moment("2015-01-01")
        .startOf("day")
        .seconds(duration.asSeconds())
        .format("H:mm:ss");
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  public showMore = (id) => {
    this.viewMoreId = this.viewMoreId === id ? -9369 : id;
  };

  private getCallRecord = async (callId) => {
    let url = null;
    try {
      const records: any = await this.recorderServiceService.getCallRecords(
        callId
      );
      if (records && records.conversations) {
        records.conversations.map((c) => {
          c &&
            c.recordings &&
            c.recordings.map((r) => {
              if (r.user === "%mixed") {
                url = r.file;
              }
            });
        });
      }
    } catch (error) {
      console.error("getCallRecords", error);
    } finally {
      return url;
    }
  };

  public playVoiceClip = async (detail,dangerTpl)=>{
  
    try {
      this.activeCallDetails = detail;
      const callPlayer = document.getElementById("callPlayer");
      callPlayer.style.display = "block";
      this.dragElement(callPlayer);
      const myVideo: any = document.getElementById("callVideoPlayer");
      const source = document.getElementById("source");
      let url = await this.getCallRecord(detail.Id);
      if(this.overwriteCallRecorderUrl === true && url !== null){
        const path = new URL(url).pathname;
        const newUrl = `${this.baseUrl}${path}`;
        console.log("playVoiceClip","overwriteCallRecorderUrl", url,newUrl);
        url = newUrl;
      }
      source.setAttribute("src", url);
      myVideo.load();
      myVideo.play();
      if (!url) {
        this.alertService.warn(`Unable to play recording file`);
      }
    } catch (error) {
      console.error(error);
      this.alertService.error(`Unable to load recording file`);
    }
  };

  public closeVoiceClip = () => {
    try {
      this.activeCallDetails = {};
      const myVideo: any = document.getElementById("callVideoPlayer");
      myVideo.pause();
      document.getElementById("callPlayer").style.display = "none";
    } catch (error) {
      console.error(error);
    }
  };

  public test = (acc) => {
    acc.collapseAll();
    acc.toggle("toggle-1");
  };

  public tests = (acc) => {
    acc.collapseAll();
    acc.toggle("toggle-2");
  };

  public dragElement = (elmnt) => {
    try {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown =
          dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    } catch (error) {
      console.error("dragElement", error);
    }
  };
}
