import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-user-create-router',
  templateUrl: './user-create-router.component.html',
  styleUrls: ['./user-create-router.component.scss']
})
export class UserCreateRouterComponent implements OnInit {

  constructor( private router: Router) {
  }

  ngOnInit() {
    this.router.navigate(["/console/colleagues/invite"]);
  }

}
