import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RecorderServiceService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrlCallRecorderApi;
  }

  public async getCallRecords(callId) {
    const reply = await this.httpClient.get(`${this.baseUrl}/wrs/recording/rest/session/${callId}`).toPromise();
    return reply ? reply : null;
  }

}
