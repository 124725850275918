import { Component, OnInit } from '@angular/core';
import { LoginHandlerService } from "../../service/auth/login-handler.service";
import {  Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent implements OnInit {
  private userName: string = "";
  private password: string = "";
  constructor(private spinner: NgxSpinnerService,private router: Router,private loginHandlerService: LoginHandlerService) { }

  ngOnInit() {
    this.spinner.show();
    const profileData = window.history.state.data;
    if (profileData && profileData.emailAddress) {
      this.userName = profileData.emailAddress;
      this.password = profileData.password;
      this.loginToSystem();
    } else {      
      this.sendToHome();
    }
  }

  private sendToHome() {
    setTimeout(() => {
      this.router.navigate(["/home"]);
    }, 3000);
  }

  private loginToSystem= async ()=>{
    try {
    const reply =  await this.loginHandlerService.Login(
        this.userName.toLowerCase(),
        this.password
      )
      if(reply.isSuccess){
        this.router.navigate(["/console/workspace"]);
      }else{
        this.router.navigate(["/home"]);
      }
    } catch (error) {
      console.error("userProfile",error);
      this.sendToHome();
    }
  }
}
