import { Component, OnInit, isDevMode } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginHandlerService } from "../../service/auth/login-handler.service";
import { WorkspaceService } from "../../service/workspace/workspace.service";
import { EventEmitterService } from "../../service/eventEmitter/event-emitter.service";
import { from } from "rxjs";
import { AlertService } from "../../_alert";
import { environment } from "../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";

@Component({
  selector: "app-console",
  templateUrl: "./console.component.html",
  styleUrls: ["./console.component.scss"],
})
export class ConsoleComponent implements OnInit {
  public workSpaces = [];
  public currentWorkspace: string;
  public routeUrl: string;
  private baseUrl: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    location: Location,
    private loginHandlerService: LoginHandlerService,
    private workspaceService: WorkspaceService,
    private eventEmitterService: EventEmitterService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    router.events.subscribe((val) => {
      this.routeUrl = location.path();
    });
    this.baseUrl = environment.TCMConsole;
  }

  async ngOnInit() {
    if (!this.loginHandlerService.GetToken()) {
      this.router.navigate(["/signIn"]);
      return;
    }
    this.currentWorkspace = this.loginHandlerService.GetWorkspaceName();
    await this.loadWorkspaces(false, null);

    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.invokeWorkspaceComponentFunction.subscribe(
        (workSpace: any) => {
          this.loadWorkspaces(true, workSpace);
        }
      );
    }
    this.spinner.hide();
    this.recaptchaV3Service.onExecute
      .subscribe((data: OnExecuteData) => {})
      .unsubscribe();

    if (isDevMode()) {
      console.log("--------------- 👋 Development! 👋 ---------------");
    } else {
      console.log("---------------  💪 Production! 💪 ---------------");
    }
  }

  public featureNotAvailable = (feature) => {
    this.alertService.onAlert(`${feature} Feature Not Available`);
  };

  private async loadWorkspaces(invokeToken: boolean, workSpace: any) {
    try {
      let workSpaces = this.loginHandlerService.GetWorkspaces();
      if (workSpace) {
        if (workSpaces) {
          workSpaces.push(workSpace);
        } else {
          workSpaces = [workSpace];
        }
      }
      this.loginHandlerService.SetWorkspaces(workSpaces);
      this.workSpaces = workSpaces;
      if (workSpaces !== null && workSpaces.length && invokeToken) {
        this.revokeToken(
          workSpace ? workSpace.workSpaceName : workSpaces[0].workSpaceName
        );
      }

      /*  const data = await this.workspaceService.getWorkspacesByOwnerName(
        this.loginHandlerService.GetUserName()
      );
      const loggedWorkspace = this.loginHandlerService.GetWorkspaceName();
      this.workSpaces = data.map(function (item) {
        item.default = item.workSpaceName === loggedWorkspace;
        return item;
      });
      this.loginHandlerService.SetWorkspaces(this.workSpaces);
      if (loggedWorkspace === null && data.length === 1 && !invokeToken) {
        this.revokeToken(data[0].workSpaceName, data.length === 1);
      } */
    } catch (ex) {
      console.error("Fail To Load Workspaces", ex);
      this.alertService.error("Fail To Load Workspaces");
    }
  }

  public async revokeToken(organization: string) {
    try {
      this.spinner.show();
      const isSuccess = await this.loginHandlerService.RevokeToken(
        organization
      );
      if (!isSuccess) {
        this.loginHandlerService.Logout();
        this.goToPage("logout");
        this.alertService.error("User Session Expired");
        this.spinner.hide();
        return;
      }
      window.location.reload();
    } catch (ex) {
      this.alertService.error("Fail To Switch Workspace");
      console.error(ex);
      this.spinner.hide();
    }
  }

  public logout() {
    this.loginHandlerService.Logout();
    this.goToPage("logout");
  }

  //go to pages
  public async goToPage(menu) {
    switch (menu) {
      case "workspace":
        this.router.navigate(["/console/workspace"]);
        break;
      case "dashboard":
        this.router.navigate(["/console/reports/dashboard"]);
        break;
      case "Report":
        this.router.navigate(["/console/reports/cdr"]);
        break;
      case "settings":
        this.router.navigate(["/console/settings/rm"]);
        break;
      case "colleagues":
        this.router.navigate(["/console/colleagues/invite"]);
        break;
      case "channel":
        this.router.navigate(["/console/channel/facebook"]);
        break;
      case "configurations":
          this.router.navigate(["/console/configurations/roledetails"]);
          break;
      case "logout":
        this.router.navigate(["/home"]);
        break;
      case "console":
        window.open(this.baseUrl, "_blank");
        break;
      default:
        break;
    }
  }
}
